/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import { KTSVG } from '../../_metronic/helpers'
import Swal from 'sweetalert2'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import { useHistory } from "react-router-dom"
import RentComplete from "../components/RentComplete"
import PaymentReceive from "../components/PaymentReceive"

function QuotationDetails() {
    const { id } = useParams();
    const history = useHistory();
    const [quotationDetails, setQuotationDetails] = useState({});

    const [showRentCompleteModal, setShowRentCompleteModal] = useState(false);
    const [showPaymentReceiveModal, setShowPaymentReceiveModal] = useState(false);
    
    useEffect(() => {
        if (id) {
            getQuotationDetails();
        }
    }, [id]);

    const getQuotationDetails = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'quotation_details/' + id,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setQuotationDetails(resp.data)
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const offerQuotation = () => {
        history.push(`/quotation-offer/${id}`)
    }

    const approve = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Approve it!',
        }).then((result) => {
            if (result.value) {
                const formData = {
                    idQuotation: id,
                }
                const requestOptions = postRequestOptions(formData)

                fetch(process.env.REACT_APP_API_URL + 'quotation_approve', requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            const successMsg = resp.successMessage
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })
                            getQuotationDetails();
                        } else {
                            const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, 'catch the hoop')
                    })
            }
        })
    }

    const quotationAction = (actionName) => {
        let confirmButtonText = '';

        if(actionName === 'rent_confirm'){
         confirmButtonText = 'Yes, Confirm the rent'
        }
        else if(actionName === 'rental_in_progress'){
            confirmButtonText = 'Yes, Rental in Progress'
        }
        //accept,review,decline
        else{
        confirmButtonText = `Yes, ${actionName} it`
        }

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: confirmButtonText,
        }).then((result) => {
            if (result.value) {
                const formData = {
                    idQuotation: id,
                    action: actionName,
                }
                const requestOptions = postRequestOptions(formData)

                fetch(process.env.REACT_APP_API_URL + 'quotation_decision', requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            const successMsg = resp.successMessage
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })
                            getQuotationDetails();
                        } else {
                            const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, 'catch the hoop')
                    })
            }
        })
    }

    const handleCompleteModalShow = () => {
        setShowRentCompleteModal(true);
    }

    const handlePayentModalShow = () => {
        setShowPaymentReceiveModal(true);
    }

    const handleCompleteModalClose = (hasComplete) => {
        setShowRentCompleteModal(false);
        if(hasComplete){
            getQuotationDetails();
        }
    }

    const handlePaymnetModalClose = (hasPayment) => {
        setShowPaymentReceiveModal(false);
        if(hasPayment){
            getQuotationDetails();
        }
    }
	

    return (
        <>
        <div className='card card-custom'>
            <div className='card-header'>
                <div className='card-title'>
                    <KTSVG
                        path='/media/icons/duotune/text/txt001.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                        Quotation Details
                    </span>
                </div>
            </div>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-6'>
                        <div className="d-flex flex-left flex-column">
                            <h3>Customer Info</h3>
                        </div>
                        <table className='table table-bordered table-hover'>
                            <tbody>
                                <tr>
                                    <th>Customer Name</th>
                                    <td>{quotationDetails?.customer_name}</td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{quotationDetails?.customer_email}</td>
                                </tr>
                                <tr>
                                    <th>Phone</th>
                                    <td>{quotationDetails?.customer_phone_number}</td>
                                </tr>
                                <tr>
                                    <th>Quotation Date</th>
                                    <td>{quotationDetails?.quotation_date}</td>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <td>
                                        {quotationDetails?.status === 'pending_for_approval' && <span className="badge badge-primary fw-bolder">Pending For Approval</span>}
                                        {quotationDetails?.status === 'approved' && <span className="badge badge-secondary fw-bolder">Approved</span>}
                                        {quotationDetails?.status === 'quotation_offered' && <span className="badge badge-info fw-bolder">Quotation Offered</span>}
                                        {quotationDetails?.status === 'quotation_accepted' && <span className="badge badge-success fw-bolder">Quotation Accepted</span>}
                                        {quotationDetails?.status === 'quotation_reviewed' && <span className="badge badge-warning fw-bolder">Quotation ask for Review</span>}
                                        {quotationDetails?.status === 'quotation_declined' && <span className="badge badge-danger fw-bolder">Quotation Declined</span>}
                                        {quotationDetails?.status === 'rent_confirmed' && <span className="badge badge-primary fw-bolder">Rent Confirmed</span>}
                                        {quotationDetails?.status === 'rental_in_progress' && <span className="badge badge-primary fw-bolder">Rental in Progress</span>}
                                        {quotationDetails?.status === 'completed' && <span className="badge badge-success  fw-bolder">Rental Completed</span>}
                                        {quotationDetails?.status === 'cancelled' && <span className="badge badge-danger fw-bolder">Rental Cancelled</span>}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='col-12'>
                        <div className='table-responsive'>
                            <table
                                className='table table-bordered table-hover'
                                style={{ minWidth: 'max-content' }}
                            >
                                <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Equipment</th>
                                        <th>From Date</th>
                                        <th>To Date</th>
                                        <th>Working Days</th>
                                        <th>Monthly Rent</th>
                                        <th>Operator Fooding</th>
                                        <th>Total Amount</th>
                                        <th>Total Operator Fooding</th>
                                        <th>Grand Total</th>
                                        <th>Location</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {quotationDetails?.quotation_details?.map((row, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{row.equipment_name}</td>
                                            <td>{row.from_date}</td>
                                            <td>{row.to_date}</td>
                                            <td>{row.working_days}</td>
                                            <td>{row.price}</td>
                                            <td>{row.operator_fooding}</td>
                                            <td>{row.total}</td>
                                            <td>{row.operator_fooding * row.working_days}</td>
                                            <td>
                                                {row.grand_total}
                                            </td>
                                            <td>
                                                {row.location_name}
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="7" style={{ textAlign: "right" }}><strong>Total</strong></td>
                                        <td>{quotationDetails?.standard_cost_total}</td>
                                        <td>{quotationDetails?.operator_fooding_total}</td>
                                        <td>{quotationDetails?.grand_total}</td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    {quotationDetails?.status === 'pending_for_approval' &&
                        <div className="row justify-content-center mt-5">
                            <div className='col-4'>
                                <div className='d-grid gap-2'>
                                    <button type="primary" className="btn btn-sm btn-primary me-2" onClick={() => approve()}>
                                        <span>  Approve</span>
                                    </button>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='d-grid gap-2'>
                                     <button type="danger" className="btn btn-sm btn-danger" onClick={() => quotationAction('cancel')}>
                                        <span> Cancel</span>
                                    </button>
                                </div>
                            </div>
                                 

                        </div>
                    }
                    {(quotationDetails?.status === 'approved' || quotationDetails?.status === 'quotation_reviewed') &&
                        <div className="row justify-content-center mt-5">
                            <div className='col-4'>
                                <div className='d-grid gap-2'>
                                    {/* <Link to={`/quotation-offer/${id}`}> */}
                                    <button type="primary" className="btn btn-sm btn-primary me-2" onClick={() => offerQuotation()}>
                                        <span>  Offer Quotation</span>
                                    </button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    }
                    {quotationDetails?.status === 'quotation_offered' &&
                        <div className="row justify-content-center mt-5">
                            <div className='col-4'>
                                <div className='d-grid gap-2'>
                                    <button type="primary" className="btn btn-sm btn-primary" onClick={() => quotationAction('accept')}>
                                        Quotation Accept
                                    </button>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='d-grid gap-2'>
                                    <button type="danger" className="btn btn-sm btn-danger" onClick={() => quotationAction('decline')}>
                                        Quotation Decline
                                    </button>
                                </div>
                            </div>

                            <div className='col-4'>
                                <div className='d-grid gap-2'>
                                    <button type="info" className="btn btn-sm btn-info" onClick={() => quotationAction('review')}>
                                        Quotation Review
                                    </button>
                                </div>
                            </div>
                        </div>

                    }

                     {quotationDetails?.status === 'quotation_accepted' &&
                        <div className="row justify-content-center mt-5">
                            <div className='col-4'>
                                <div className='d-grid gap-2'>
                                    <button type="primary" className="btn btn-sm btn-primary" onClick={() => quotationAction('rent_confirm')}>
                                        Rent Confirm
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    }

                {quotationDetails?.status === 'rent_confirmed' &&
                        <div className="row justify-content-center mt-5">
                            <div className='col-4'>
                                <div className='d-grid gap-2'>
                                    <button type="primary" className="btn btn-sm btn-primary" onClick={() => quotationAction('rental_in_progress')}>
                                    Rental in Progress
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                }


                {quotationDetails?.status === 'rental_in_progress' &&
                        <div className="row justify-content-center mt-5">
                            <div className='col-4'>
                                <div className='d-grid gap-2'>
                                    <button type="primary" className="btn btn-sm btn-primary" onClick={() =>handleCompleteModalShow() }>
                                    Complete Rental
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    }


            {(quotationDetails?.status === 'completed' || quotationDetails?.status === 'rental_in_progress' || quotationDetails?.status === 'rent_confirmed')&&
                        <div className="row justify-content-center mt-5">
                            <div className='col-4'>
                                <div className='d-grid gap-2'>
                                    <button type="primary" className="btn btn-sm btn-primary" onClick={() =>handlePayentModalShow() }>
                                        Payment  Receive
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                    }


                    <div className="col-12">
                        <div className="d-flex flex-left flex-column pt-9">
                            <h3>Terms & Conditions</h3>
                        </div>
                        <table
                            className='table table-bordered table-hover'
                        >
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th className="text-center">Conditions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {quotationDetails?.terms_condition?.map((row, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{row.text}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
        <RentComplete show={showRentCompleteModal} handleClose={handleCompleteModalClose}  idQuotation={id}  />
        <PaymentReceive show={showPaymentReceiveModal} handleClose={handlePaymnetModalClose}  idQuotation={id}  />
    </>
    )
}

export default QuotationDetails