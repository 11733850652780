/* eslint-disable no-unused-vars */
import { useState, createContext, useEffect } from "react";
import { getRequestOptions } from "../helpers/Fetchwrapper";
export const AppContext = createContext();


function AppContextProvider(props) {
    const [currentUser, setCurrentUser] = useState();
    const [token, setToken] = useState(null);
    const [uiReload, setUiReload] = useState(false);

    useEffect(() => {
        setToken(JSON.parse(localStorage.getItem("MyToken")) ? JSON.parse(localStorage.getItem("MyToken")) : null);
    }, []);

    useEffect(() => {
        if (token !== null) {
            getUserInfo();
        }
    }, [token]);

    useEffect(() => {
        if (uiReload === true) {
            getUserInfo();
        }
    }, [uiReload]);

    const getUserInfo = async () => {
        try {
            const requestOptions = getRequestOptions();
            const response = await fetch(`${process.env.REACT_APP_API_URL}users/get_user_info`, requestOptions);
            const resp = await response.json();
            setCurrentUser(resp.data);
            setUiReload(false);
        }
        catch (error) {
            const removeJwt = () => { return localStorage.removeItem('MyToken'); }
            removeJwt();
            setToken(null);
            setUiReload(false);
        }
    }

    const updateToken = (tokenData) => {
        setToken(tokenData);
    }

    const userInfoReload = () => {
        setUiReload(true);
    }

    const value = { currentUser, token, updateToken, userInfoReload };

    return (
        <AppContext.Provider value={value}>
            {props.children}
        </AppContext.Provider>
    );



}

export default AppContextProvider;