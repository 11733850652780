
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FallbackView } from '../../_metronic/partials';
import Dashboard from '../pages/Dashboard';
import PageList from '../pages/PageList';
import PageManagement from '../pages/PageManagement';
import RoleManagement from '../pages/RoleManagement';
import UserRolePermission from '../pages/UserRolePermission';
import Logout from "../pages/Logout";
import Users from "../pages/Users";
import Categories from '../pages/Categories';
import CategoryEquipment from '../pages/CategoryEquipment';
import EquipmentCreate from '../pages/EquipmentCreate';
import EquipmentList from '../pages/EquipmentList';
import EquipmentDetails from '../pages/EquipmentDetails';
import MyProfile from '../pages/MyProfile';
import TermsCondition from '../pages/TermsCondition';
import EquipmentEdit from '../pages/EquipmentEdit';
import QuotationList from '../pages/QuotationList';
import QuotationOffer from '../pages/QuotationOffer';
import QuotationDetails from '../pages/QuotationDetails';
import VendorCreate from '../pages/VendorCreate';
import VendorList from '../pages/VendorList';
import VendorDetails from '../pages/VendorDetails';

export function PrivateRoutes() {

	return (
		<Suspense fallback={<FallbackView />}>
			<Switch>
				<Route path='/dashboard' component={Dashboard} />
				<Route path='/setup-categories' component={Categories} />
				<Route path='/setup-category-equipment' component={CategoryEquipment} />
				<Route path='/setup-terms-coditions' component={TermsCondition} />
				<Route path='/accesscontrol-users' component={Users} />
				<Route path='/accesscontrol-rolemanagement' component={RoleManagement} />
				<Route path='/accesscontrol-userrolepermission' component={UserRolePermission} />
				<Route path='/accesscontrol-page-list' component={PageList} />
				<Route path='/accesscontrol-pagemanagement' component={PageManagement} />
				<Route path="/logout" component={Logout} />
				<Route path="/my-profile" component={MyProfile} />
				<Route path="/equipment-create" component={EquipmentCreate} />
				<Route path="/equipment-list" component={EquipmentList} />
				<Route path="/equipment-details/:id" component={EquipmentDetails} />
				<Route path="/equipment-edit/:id" component={EquipmentEdit} />
				<Route path="/quotation-list" component={QuotationList} />
				<Route path="/quotation-offer/:id" component={QuotationOffer} />
				<Route path="/quotation-details/:id" component={QuotationDetails} />
				<Route path="/vendor-add" component={VendorCreate} />
				<Route path="/vendor-list" component={VendorList} />
				<Route path="/vendor-details/:id" component={VendorDetails} />
				<Redirect exact from='/' to='/dashboard' />
				<Redirect to='error/404' />
			</Switch>
		</Suspense>
	)
}
