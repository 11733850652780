import React, { useState } from 'react'
import DataTable from '../components/DataTable'
import { KTSVG } from '../../_metronic/helpers'
import CategoryEquipmentCreate from '../components/CategoryEquipmentCreate'
import Error from '../components/Error500'
import GetPagePermission from "../components/GetPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Swal from 'sweetalert2';

function CategoryEquipment() {

    const hasPermission = GetPagePermission("/setup-category-equipment");
    const [newData, setNewData] = useState('');
    const [id, setId] = useState('');

    const onCreate = (data) => {
        setNewData({ id: data });
        setId('');
    }

    const columns = [
        { value: 'id', title: 'Id', style: { textAlign: 'center' } },
        { value: 'categoryName', title: 'Category', style: { textAlign: 'left' } },
        { value: 'equipmentName', title: 'Equipment Size', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'center' }, hasActionsComponent: true, actionsComponent: ((row) => {
                return (
                    <>
                        <button className="btn btn-light-primary btn-sm" style={{ whiteSpace: 'nowrap' }} onClick={() => onAction(row)}>
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1 svg-icon-3' />
                            Edit
                        </button>
                        <button className="btn btn-light-danger btn-sm" onClick={() => deleteCategoryEquipment(row.id)}>
                            <KTSVG
                                path='/media/svg/icons/General/Trash.svg'
                                className='svg-icon-5 svg-icon'
                            />		Delete
                        </button>
                    </>)

            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        categoryName: {
            filterType: 'like',
            filterValue: '',
        },
        equipmentName: {
            filterType: 'like',
            filterValue: '',
        }

    }

    const url = process.env.REACT_APP_API_URL + 'category_equipment_dt'

    const onAction = (data) => {
        setId(data.id);
    }

    const deleteCategoryEquipment = (idCategoryEquipment) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this equipment size!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',

        }).then((result) => {
            if (result.value) {
                let jwt = JSON.parse(localStorage.getItem('MyToken'));
                const requestOptions = {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
                };
                var apiEnd = "category_equipment_delete/" + idCategoryEquipment;
                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        console.log(resp);
                        if (resp.success === true) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: "Equipment Size deleted successfully",
                            })
                            onCreate(Math.random() * 100)

                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: resp.errorMessage
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            }
        })
    }

    return (
        <>
            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <KTSVG
                                path='/media/svg/icons/Files/File-plus.svg'
                                className='svg-icon-1 svg-icon-primary'
                            />
                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                Add Equipment Size
                            </span>
                        </div>
                    </div>

                    <div className='card-body'>
                        <CategoryEquipmentCreate onCreate={onCreate} id={id} />
                        <div className="card-header">
                            <div className="card-title">
                                <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                                <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                    Equipment Size List
                                </span>
                            </div>
                        </div>
                        <div className='card-body'>
                            <DataTable url={url} columns={columns} filters={filters} newData={newData} />
                        </div>
                    </div>
                </div>
            }
            {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === '' &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }
        </>
    )
}

export default CategoryEquipment