/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from '../../_metronic/layout/core'
import { useHistory } from 'react-router-dom'

const jwt = () => { return JSON.parse(localStorage.getItem('MyToken')); }
export default function Logout() {
	let history = useHistory();

	useEffect(() => {
		removeJwt();
	}, [])

	const removeJwt = () => {
		localStorage.removeItem('MyToken');
		history.push('/login');
	}

	return (
		<>
			{jwt ? <LayoutSplashScreen /> : <Redirect to={{ pathname: '/login' }} />}
		</>
	)
}



