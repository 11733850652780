/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from "../helpers/Fetchwrapper";
import validationErrorMsg from "../helpers/ValidationErrorMsg";

function TermsCondition(props) {
    const [id, setId] = useState('');
    const [text, setText] = useState('');
    const [sequence, setSequence] = useState('');

    const myRef = useRef();

    useEffect(() => {
        if (id !== props.id) {
            setId(props.id);
            getTermsConditionDetails(props.id);
            window.scrollTo(0, 0);
            myRef.current.focus();
        }
    }, [props.id]);

    const getTermsConditionDetails = (idTermsConditions) => {
        fetch(process.env.REACT_APP_API_URL + "terms_condition_details/" + idTermsConditions,
            getRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setText(resp.text);
                setSequence(resp.sequence);
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }


    const handleSubmit = (evt) => {
        evt.preventDefault()
        if (id !== '') {
            var warningMessage = 'You want to update this Terms Condition!'
        } else {
            warningMessage = 'You want to add this Terms Condition!'
        }
        Swal.fire({
            title: 'Are you sure?',
            text: warningMessage,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                const formData = {
                    id: id,
                    text: text,
                    sequence: sequence,
                }

                const requestOptions = postRequestOptions(formData);

                if (id !== '') {
                    var apiEnd = 'terms_condition_update'
                } else {
                    apiEnd = 'terms_condition_create'
                }

                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {

                        if (resp.success === true) {
                            if (id !== '') {
                                var successMsg = [`Terms Condition updated`]
                            } else {
                                successMsg = [`Terms Condition added`]
                            }

                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })
                            setId('');
                            setText('');
                            setSequence('');
                            props.onCreate({ id: resp.data.id })
                        } else {

                            const errorsMessage = validationErrorMsg(resp.errors).join("<br>");
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, 'catch the hoop')
                    })
            }
        })
    }

    return (
        <div className='card-body'>
            <div className='ml-12' >
                <form onSubmit={handleSubmit}>
                    <div className='form-group row'>
                        <label
                            htmlFor='text'
                            className='col-lg-3 col-form-label'
                            style={{ textAlign: 'right' }}
                        >
                            Text<span className='required text-danger'></span>
                        </label>
                        <div className='col-lg-5'>
                            <textarea
                                type='text'
                                name='text'
                                id='text'
                                ref={myRef}
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                className='form-control form-control-sm'
                            />
                        </div>
                    </div>

                    <div className='form-group row mt-2'>
                        <label
                            htmlFor='sequence'
                            className='col-lg-3 col-form-label'
                            style={{ textAlign: 'right' }}
                        >
                            Sequence<span className='required text-danger'></span>
                        </label>
                        <div className='col-lg-5'>
                            <input
                                type='number'
                                name='sequence'
                                id='sequence'
                                value={sequence}
                                onChange={(e) => setSequence(e.target.value)}
                                className='form-control form-control-sm'
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-4'></div>
                        <div className='col-6'>
                            <input
                                type='submit'
                                className='btn btn-sm btn-primary mt-2'
                                value={id !== '' ? 'Update' : 'Save'}
                            />

                        </div>
                    </div>
                    {/* <pre>
            {JSON.stringify(name, null, 2)}
            {JSON.stringify(status, null, 2)}
          </pre> */}
                </form>
            </div>
        </div>
    )
}

export default TermsCondition
