/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import Swal from 'sweetalert2';
import validationErrorMsg from '../helpers/ValidationErrorMsg';
import { KTSVG } from '../../_metronic/helpers'
import AsyncSelect from 'react-select/async'
import Select from 'react-select';

function QuotationOffer() {
    const { id } = useParams();
    const history = useHistory();
    const [locations, setLocations] = useState([]);
    const [standardCostTotal, setStandardCostTotal] = useState(0);
    const [operatorFooding, setOperatorFooding] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        getQuotationDetails();
        getAllLocations();
    }, []);

    useEffect(() => {
        if (formData.quotationDetails) {
            calcTotal();
        }
    }, [formData.quotationDetails]);

    const getQuotationDetails = () => {
        fetch(process.env.REACT_APP_API_URL + 'get_quotation_details/' + id,
            getRequestOptions())

            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                if (resp.status === true) {
                    setFormData({
                        quotationInfo: {
                            idQuotationDraft: id,
                            customerId: resp.data.customer_id,
                            quotationDate: resp.data.quotation_date,
                            customerName: resp.data.customer_name,
                            customerEmail: resp.data.customer_email,
                            customerPhoneNumber: resp.data.customer_phone_number,
                            termsCondition: JSON.parse(resp.data.terms_condition)
                        },
                        quotationDetails: resp.data.quotation_details.map(function (element) {
                            return ({
                                idQuotationDraftDetails: element.id_quotation_draft_details,
                                fromDate: element.from_date,
                                toDate: element.to_date,
                                price: Number(element.price),
                                operatorFooding: Number(element.operator_fooding),
                                workingDays: Number(element.working_days),
                                equipment: {
                                    label: element.equipment_name,
                                    value: element.id_equipment,
                                    idEquipment: element.id_equipment,
                                    equipmentName: element.equipment_name
                                },
                                location: {
                                    label: element.location_name,
                                    value: element.id_locations,
                                    idLocations: element.id_locations,
                                    locationName: element.location_name
                                },
                                totalAmount: Number(element.price) / 20 * Number(element.working_days),
                                operatorFoodingTotal: Number(element.operator_fooding) * Number(element.working_days),
                                grandTotal: Number(element.price) / 20 * Number(element.working_days) + (Number(element.operator_fooding) * Number(element.working_days))
                            })
                        })
                    });
                }
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    const getAllLocations = () => {
        fetch(process.env.REACT_APP_API_URL + "get_all_locations",
            getRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let newItems = resp.data.map(function (element) {
                    return { ...element, label: element.locationName, value: element.idLocations }
                });
                setLocations(newItems);
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    const getAllEquipments = (inputValue, callback) => {

        if (!inputValue) {
            var url = process.env.REACT_APP_API_URL + "get_all_equipments";

        }
        else {

            url = process.env.REACT_APP_API_URL + "get_all_equipments?q=" + inputValue;
        }

        setTimeout(() => {
            fetch(url,
                getRequestOptions())

                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    const tempArray = resp.data.map(function (element) {
                        element.label = element.equipmentName;
                        element.value = element.idEquipment;
                        return element;
                    });
                    callback(tempArray);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        });
    }

    const handleEquipmentChange = (index) => (selectedOption) => {
        const newItems = [...formData.quotationDetails];
        newItems[index].equipment = selectedOption;
        setFormData({ ...formData, quotationDetails: newItems });
    }

    const handleFromDateChange = (index) => (e) => {
        const newItems = [...formData.quotationDetails];
        newItems[index].fromDate = e.target.value;
        if (newItems[index].toDate !== '') {
            newItems[index].workingDays = getWorkingDays(newItems[index].fromDate, newItems[index].toDate);
            newItems[index].totalAmount = Number(newItems[index].price / 20) * Number(newItems[index].workingDays);
            newItems[index].operatorFoodingTotal = Number(newItems[index].operatorFooding) * Number(newItems[index].workingDays);
            newItems[index].grandTotal = Number(newItems[index].totalAmount) + (Number(newItems[index].operatorFooding) * Number(newItems[index].workingDays));
        }
        setFormData({ ...formData, quotationDetails: newItems });
    }

    const handleToDateChange = (index) => (e) => {
        const newItems = [...formData.quotationDetails];
        newItems[index].toDate = e.target.value;
        if (newItems[index].fromDate !== '') {
            newItems[index].workingDays = getWorkingDays(newItems[index].fromDate, newItems[index].toDate);
            newItems[index].totalAmount = Number(newItems[index].price / 20) * Number(newItems[index].workingDays);
            newItems[index].operatorFoodingTotal = Number(newItems[index].operatorFooding) * Number(newItems[index].workingDays);
            newItems[index].grandTotal = Number(newItems[index].totalAmount) + (Number(newItems[index].operatorFooding) * Number(newItems[index].workingDays));
        }
        setFormData({ ...formData, quotationDetails: newItems });
    }

    const handlePriceChange = (index) => (e) => {
        const newItems = [...formData.quotationDetails];
        newItems[index].price = e.target.value;
        newItems[index].totalAmount = Number(newItems[index].price / 20) * Number(newItems[index].workingDays);
        newItems[index].operatorFoodingTotal = Number(newItems[index].operatorFooding) * Number(newItems[index].workingDays);
        newItems[index].grandTotal = Number(newItems[index].totalAmount) + (Number(newItems[index].operatorFooding) * Number(newItems[index].workingDays));
        setFormData({ ...formData, quotationDetails: newItems });
    }

    const handleOperatorFoodingChange = (index) => (e) => {
        const newItems = [...formData.quotationDetails];
        newItems[index].operatorFooding = e.target.value;
        newItems[index].operatorFoodingTotal = Number(newItems[index].operatorFooding) * Number(newItems[index].workingDays);
        newItems[index].grandTotal = Number(newItems[index].totalAmount) + (Number(newItems[index].operatorFooding) * Number(newItems[index].workingDays));
        setFormData({ ...formData, quotationDetails: newItems });
    }

    const handleLocationChange = (index) => (selectedOption) => {
        const newItems = [...formData.quotationDetails];
        newItems[index].location = selectedOption;
        setFormData({ ...formData, quotationDetails: newItems });
    }

    const handleTotalAmountChange = (index) => (e) => {
        const newItems = [...formData.quotationDetails];
        newItems[index].totalAmount = e.target.value;
        setFormData({ ...formData, quotationDetails: newItems });
    }

    const handleTermsConditionsChange = (index) => (e) => {
        const newItems = [...formData.termsCondition];
        newItems[index].text = e.target.value;
        setFormData({ ...formData, termsCondition: newItems });
    }

    const addItem = () => {
        setFormData({ ...formData, quotationDetails: formData.quotationDetails.concat({ fromDate: '', toDate: '', price: 0, operatorFooding: 0, workingDays: 0, equipment: '', location: '', totalAmount: 0, grandTotal: 0, operatorFoodingTotal: 0 }) });
    }

    const removeItem = (index) => () => {
        setFormData({ ...formData, quotationDetails: formData.quotationDetails.filter((row, rindex) => index !== rindex) });
    }

    const addTermsCondition = () => {
        setFormData({ ...formData, termsCondition: formData.termsCondition.concat({ text: '' }) });
    }

    const removeTermsCOndition = (index) => () => {
        setFormData({ ...formData, termsCondition: formData.termsCondition.filter((row, rindex) => index !== rindex) });
    }

    const calcTotal = () => {
        setStandardCostTotal(objPsum(formData.quotationDetails, 'totalAmount'));
        setOperatorFooding(objPsum(formData.quotationDetails, 'operatorFoodingTotal'));
        setGrandTotal(objPsum(formData.quotationDetails, 'totalAmount') + objPsum(formData.quotationDetails, 'operatorFoodingTotal'))
    }

    const getWorkingDays = (fromDate, toDate) => {
        const date1 = new Date(fromDate);
        const date2 = new Date(toDate);
        const diffTime = Math.abs(date2.getTime() - date1.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    const objPsum = (items, prop) => {
        return items.reduce(function (a, b) {
            return Number(a) + Number(b[prop]);
        }, 0);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.value) {
                const requestOptions = postRequestOptions(formData)

                fetch(process.env.REACT_APP_API_URL + 'quotation_offer', requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            const successMsg = resp.successMessage
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })
                            history.push('/quotation-details/' + resp.data.id_quotation_draft);
                        } else {
                            const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, 'catch the hoop')
                    })
            }
        })
    }

    return (
        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                    <span>
                        <KTSVG
                            path='/media/svg/icons/Devices/Server.svg'
                            className='svg-icon-1 svg-icon-primary'
                        />
                    </span>
                    <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                        Offer Quotation
                    </span>
                </div>
            </div>

            <div className="ml-12">
                <form onSubmit={handleSubmit}>
                    <div className="card-body">
                        <div className="mt-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <h4> Customer Info</h4>
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>Name  </td>
                                                <td>{formData?.quotationInfo?.customerName}</td>
                                            </tr>
                                            <tr>
                                                <td>Email  </td>
                                                <td>{formData?.quotationInfo?.customerEmail}</td>
                                            </tr>
                                            <tr>
                                                <td>Phone  </td>
                                                <td>{formData?.quotationInfo?.customerPhoneNumber}</td>
                                            </tr>
                                            <tr>
                                                <td>Quotation Date</td>
                                                <td>{formData?.quotationInfo?.quotationDate}</td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                            <div className="table-responsive" style={{ margin: "5px" }}>
                                <table className="table table-bordered table-hover" style={{ minWidth: 'max-content' }}>
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th style={{ width: "15%" }}>Equipment</th>
                                            <th>From Date</th>
                                            <th>To Date</th>
                                            <th>Working Days</th>
                                            <th>Monthly Rent</th>
                                            <th>Total Amount</th>
                                            <th>Operator Fooding</th>
                                            <th>Grand Total</th>
                                            <th>Location</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            formData?.quotationDetails?.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <AsyncSelect
                                                            value={item.equipment}
                                                            defaultOptions
                                                            loadOptions={getAllEquipments}
                                                            placeholder="Select Equipment"
                                                            onChange={handleEquipmentChange(index)}
                                                            menuPosition="fixed"

                                                        />

                                                    </td>
                                                    <td><input type="date" value={item.fromDate} onChange={handleFromDateChange(index)} className="form-control form-control-sm" /></td>
                                                    <td><input type="date" value={item.toDate} onChange={handleToDateChange(index)} className="form-control form-control-sm" /></td>
                                                    <td><input type="number" value={item.workingDays} className="form-control form-control-sm" readOnly /></td>
                                                    <td><input type="number" value={item.price} onChange={handlePriceChange(index)} className="form-control form-control-sm" /></td>
                                                    <td><input type="number" value={item.totalAmount} onChange={handleTotalAmountChange(index)} className="form-control form-control-sm" readOnly /></td>
                                                    <td><input type="number" value={item.operatorFooding} onChange={handleOperatorFoodingChange(index)} className="form-control form-control-sm" /></td>
                                                    <td><input type="number" value={item.grandTotal} className="form-control form-control-sm" readOnly /></td>
                                                    <td><Select value={item.location} onChange={handleLocationChange(index)} options={locations} menuPosition="fixed" placeholder="Select Location" /></td>
                                                    <td style={{ whiteSpace: "nowrap" }}>
                                                        {index === (formData?.quotationDetails?.length - 1) &&
                                                            <button type="button" className="btn btn-icon btn-light btn-active-light-primary  btn-sm me-1" onClick={addItem}>
                                                                <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon svg-icon-5' />
                                                            </button>
                                                        }
                                                        {formData?.quotationDetails?.length > 1 &&
                                                            <button type="button" className="btn btn-icon btn-light btn-active-light-danger btn-sm" onClick={removeItem(index)}>
                                                                <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon svg-icon-5' />
                                                            </button>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-7">
                                <div className="d-flex flex-left flex-column pt-9">
                                    <h3>Terms & Conditions</h3>
                                </div>
                                <div style={{ maxHeight: "500px", overflow: "auto" }}>
                                    <table className="table table-bordered table-hover">
                                        <tbody>
                                            {formData?.quotationInfo?.termsCondition && formData?.quotationInfo?.termsCondition.map((item, index) =>
                                                <tr key={index}>
                                                    <td style={{ width: "5%" }}>{index + 1}</td>
                                                    {/* <div className="input-group mt-1"> */}
                                                    <td>
                                                        <input type="text" key={index} value={item.text} className="form-control form-control-sm" onChange={handleTermsConditionsChange(index)} />
                                                    </td>
                                                    <td style={{ width: "5%", whiteSpace: "nowrap" }}>
                                                        {index === (formData?.quotationInfo?.termsCondition.length - 1) &&
                                                            <button type="button" className="btn btn-icon btn-light-primary  btn-sm me-1" onClick={addTermsCondition}>
                                                                <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon svg-icon-5' />
                                                            </button>
                                                        }
                                                        {
                                                            formData?.quotationInfo?.termsCondition.length > 1 &&
                                                            <button type="button" className="btn btn-icon btn-light-danger btn-sm" onClick={removeTermsCOndition(index)}>
                                                                <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon svg-icon-5' />
                                                            </button>
                                                        }

                                                    </td>
                                                    {/* </div> */}
                                                </tr>
                                            )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="d-flex flex-left flex-column pt-9">
                                    <h3>Summary</h3>
                                </div>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>Equipment Standard Cost</td>
                                            <td>{standardCostTotal}</td>
                                        </tr>
                                        <tr>
                                            <td>Operator Salary & Fooding</td>
                                            <td>{operatorFooding}</td>
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td>{grandTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4"></div>
                        <div className='col-4'>
                            <div className='d-grid gap-2'>

                                <button className="btn btn-sm btn-primary me-2" type='submit'>
                                    Offer
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* <pre>
                        {JSON.stringify(formData, null, 2)}
                    </pre> */}
                </form>
            </div>
        </div>
    )
}

export default QuotationOffer;