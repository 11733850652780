import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Spinner from 'react-bootstrap/Spinner'

export default function RentComplete({ show, handleClose, idQuotation }) {


    const [completeNote, setCompleteNote] = useState(null);
    const [isSubmitDisable, setSubmitDisable] = useState(false);
    const [loading, setLoading] = useState(false);

    const completeTaskSubmit = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Complete it!',
        }).then((result) => {
            if (result.value) {
                setSubmitDisable(true);
                setLoading(true);
                const formData = {
                    idQuotation: idQuotation,
                    remark: completeNote
                }
                const requestOptions = postRequestOptions(formData)

                fetch(process.env.REACT_APP_API_URL + 'rental_complete', requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        setLoading(false);
                        setSubmitDisable(false);
                        if (resp.success === true) {
                            const successMsg = resp.successMessage
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })

                            handleClose(true)
                        } else {
                            const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        setSubmitDisable(false);
                        console.log(error, 'catch the hoop')
                    })
            }
        })

    }

    return (
        <Modal show={show} onHide={() => handleClose(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Rental Complete</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <div className='card-body'>

                    <div className='ml-12'>
                       
                        <div className='form-group row'>
                            <label htmlFor='completeNote' className='col-4 col-form-label'>
                                Remark
                            </label>
                            <div className='col-6'>
                                <textarea
                                    name='completeNote'
                                    id='completeNote'
                                    value={completeNote || ''}
                                    onChange={(e) => setCompleteNote(e.target.value)}
                                    className='form-control form-control-sm'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant='primary' onClick={completeTaskSubmit} size="sm">
            Complete
        </Button> */}
                <button disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2" onClick={completeTaskSubmit}>
                    {loading &&
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                    <span>  Complete</span>
                </button>

                <Button variant='secondary' onClick={() => handleClose(false)} size="sm">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
