/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { useParams } from 'react-router-dom'
import { getRequestOptions } from '../helpers/Fetchwrapper'
import axios from 'axios';
import Swal from 'sweetalert2';
import { AppContext } from '../context/AppContext'
import Form from 'react-bootstrap/Form';

const EquipmentDetails = () => {
    const { id } = useParams();
    const { token } = useContext(AppContext);
    const [equipmentDetails, setEquipmentDetails] = useState({});
    const [tab, setTab] = useState("info");
    const [imageAttachment, setImageAttachment] = useState(null);
    const [image, setImage] = useState([]);
    const [imageTitle, setImageTitle] = useState("");
    const [reload, setReload] = useState(false);

    useEffect(() => {
        if (id !== undefined) {
            getEquipmentDetails(id);
            getEquipmentImages(id);
        }

    }, [id])

    useEffect(() => {
        if (reload) {
            getEquipmentImages(id);
        }
    }, [reload])

    const getEquipmentDetails = (id) => {
        fetch(
            process.env.REACT_APP_API_URL + 'equipment_details/' + id,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setEquipmentDetails(resp.data)
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const getEquipmentImages = (id) => {
        fetch(process.env.REACT_APP_API_URL + "equipment_images/" + id, getRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setImage(resp.data);
                setReload(false);
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }


    const tabChange = tab => {
        setTab(tab);
    }

    // const onImageChange = event => {
    //     // setImageAttachment({ ...imageAttachment, image: event.target.files[0], lastModified: event.target.files[0].lastModified, name: event.target.files[0].name, size: event.target.files[0].size, type: event.target.files[0].type });
    //     // const fileList = event.target.files;
    //     // for (var i = 0; i < fileList.length; i++) {
    //     //     var imageFile[i] = fileList[i];
    //     // }
    //     // setImageAttachment(imageFile);
    // }

    const onImageChange = event => {
        const selectedFiles = event.target.files;
        const updatedAttachments = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            updatedAttachments.push({
                image: selectedFiles[i],
                lastModified: selectedFiles[i].lastModified,
                name: selectedFiles[i].name,
                size: selectedFiles[i].size,
                type: selectedFiles[i].type
            });
        }

        setImageAttachment(updatedAttachments);
    };

    const handleInputOnChange = (e) => {
        setImageTitle(e.target.value);
    }

    const resetAttachedUploadImage = () => {
        setImageAttachment([]);
    }

    const handleAttachedImageSubmit = evt => {
        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to upload this attached image!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                if (imageAttachment.length > 0) {
                    const formData = new FormData();
                    for (var i = 0; i < imageAttachment.length; i++) {
                        formData.append('attachedImage[]', imageAttachment[i].image);
                    }
                    formData.append("idEquipment", id);
                    formData.append("imageTitle", imageTitle);
                    axios.post(process.env.REACT_APP_API_URL + "equipment_image_upload", formData, {
                        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + token },
                    })
                        .then(response => {
                            console.log(response)
                            if (response.data.success === true) {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.successMessage,
                                })
                                setImageAttachment([]);
                                setImageTitle("");
                                setReload(true);
                            }
                            else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    html: response.data.errorMessage
                                })
                            }
                        })
                        .catch((error) => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: error.response.data.errorMessage
                            })
                        })
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: 'Please attach a image before submit'
                    })
                }
            }
        })
    }

    const isMainChange = (idEquipmentImages) => {
        const formData = { idEquipmentImages: idEquipmentImages };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(formData)
        };
        fetch(process.env.REACT_APP_API_URL + "set_equipment_main_image", requestOptions)
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                if (resp.success === true) {
                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "Main Image Changed",
                        showConfirmButton: false
                    });
                    setReload(true);
                }
                else {
                    var errorsMessage = [];

                    if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                        var errorsObj = resp.errorMessage;
                        Object.keys(errorsObj).forEach(function (value) {
                            errorsObj[value].forEach(function (v) {
                                errorsMessage.push(v)
                            });

                        });

                    } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                        errorsMessage.push(resp.errorMessage);
                    } else {

                        errorsMessage.push("Something went wrong");
                    }
                    Swal.fire({
                        icon: 'error',
                        title: resp.heading,
                        text: errorsMessage,
                    })
                }
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    const removeFile = (idEquipmentImages) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this attached image!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {

                const requestOptions = {
                    method: 'Delete',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
                };
                fetch(process.env.REACT_APP_API_URL + "equipment_image_delete/" + idEquipmentImages, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            Swal.fire({
                                position: "top-right",
                                icon: "success",
                                title: "Image Deleted Successfully",
                                showConfirmButton: false
                            });
                            setReload(true);
                        }
                        else {
                            var errorsMessage = [];

                            if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                                var errorsObj = resp.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                    });

                                });

                            } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                errorsMessage.push(resp.errorMessage);
                            } else {

                                errorsMessage.push("Something went wrong");
                            }
                            Swal.fire({
                                icon: 'error',
                                title: resp.heading,
                                text: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            }
        })
    }

    const imagePath = process.env.REACT_APP_AWS_URL + 'toolpati/' + id;

    return (
        <>

            <div className='card card-custom'>
                <div className='card-header'>
                    <div className='card-title'>
                        <KTSVG
                            path='/media/icons/duotune/text/txt001.svg'
                            className='svg-icon-1 svg-icon-primary'
                        />
                        <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                            Equipment Details
                        </span>
                    </div>
                </div>
                <div className="card-body">
                    <ul className="nav nav-tabs nav-tabs-line">
                        <li className="nav-item" onClick={() => tabChange("info")}>
                            <a className={`nav-link ${tab === "info" && "active"}`} data-toggle="tab" role="tab" aria-selected={(tab === "info").toString()}>Info</a>
                        </li>
                        <li className="nav-item" onClick={() => tabChange("image")}>
                            <a className={`nav-link ${tab === "image" && "active"}`} data-toggle="tab" role="tab" aria-selected={(tab === "image").toString()}>Image</a>
                        </li>
                    </ul>
                    {tab === "info" &&
                        <div className="row">
                            <div className="col-md-8">
                                <table className='table table-bordered table-hover'>
                                    <tbody>
                                        <tr>
                                            <th>Vendor</th>
                                            <td>{equipmentDetails.vendor}</td>
                                        </tr>
                                        <tr>
                                            <th>Category</th>
                                            <td>{equipmentDetails.categoryName}</td>
                                        </tr>
                                        <tr>
                                            <th>Equipment Name</th>
                                            <td>{equipmentDetails.equipmentName}</td>
                                        </tr>
                                        <tr>
                                            <th>Vendor</th>
                                            <td>{equipmentDetails.vendor}</td>
                                        </tr>
                                        <tr>
                                            <th>Brand</th>
                                            <td>{equipmentDetails.brandName}</td>
                                        </tr>
                                        <tr>
                                            <th>Model</th>
                                            <td>{equipmentDetails.model}</td>
                                        </tr>
                                        <tr>
                                            <th>Capacity</th>
                                            <td>{equipmentDetails.capacity}</td>
                                        </tr>
                                        <tr>
                                            <th>Operating Weight</th>
                                            <td>{equipmentDetails.operatingWeight}</td>
                                        </tr>
                                        <tr>
                                            <th>Manufacturing Year</th>
                                            <td>{equipmentDetails.yearOfManufacture}</td>
                                        </tr>
                                        <tr>
                                            <th>Rent Rate</th>
                                            <td>{equipmentDetails.rentRate}</td>
                                        </tr>
                                        <tr>
                                            <th>Operator Salaray & Fooding</th>
                                            <td>{equipmentDetails.operatorFooding}</td>
                                        </tr>
                                        <tr>
                                            <th>Yard Location</th>
                                            <td>{equipmentDetails.yardLocation}</td>
                                        </tr>
                                        <tr>
                                            <th>Description</th>
                                            <td dangerouslySetInnerHTML={{ __html: equipmentDetails.description }}></td>
                                        </tr>
                                        <tr>
                                            <th>Status</th>
                                            <td>{equipmentDetails.status === 'active' ? <span className="badge badge-light-success fw-bolder">Active</span> : <span className="badge badge-light-danger fw-bolder">Inactive</span>}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    }
                    {tab === "image" &&
                        <>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <form onSubmit={handleAttachedImageSubmit}>
                                        <div className="mt-5">
                                            <div className="form-group row">
                                                <label htmlFor="imageAttachment" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Attach Image </label>
                                                <div className="col-lg-8">
                                                    <Form.Control type="file" size="sm" name="imageAttachment" id="imageAttachment" onChange={onImageChange} onClick={e => (e.target.value = null)} multiple />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="imageTitle" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Image Title</label>
                                                <div className="col-lg-8">
                                                    <input type="text" name="imageTitle" id="imageTitle" value={imageTitle} className="form-control form-control-sm" onChange={handleInputOnChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                            </div>
                                            <div className="col-6">
                                                <button type="submit" className="btn btn-success btn-sm mb-5">Attach</button>
                                                <button type="reset" className="btn btn-danger btn-sm mb-5" onClick={() => resetAttachedUploadImage()}>Reset</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-8">
                                    <table className="table table-bordered table-hover mt-2">
                                        <thead>
                                            <tr>
                                                <td>Sl</td>
                                                <td>Image</td>
                                                <td>Name</td>
                                                <td>Title</td>
                                                <td style={{ "textAlign": "center" }}>Main Image</td>
                                                <td style={{ "textAlign": "center" }}>Action</td>
                                            </tr>
                                        </thead>
                                        {image.length > 0 ?
                                            <tbody>
                                                {image.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <img src={`${imagePath}/thumb/${item.thumbnailName}`} alt="" style={{ width: "100px", height: "100px" }} />
                                                        </td>
                                                        <td>{item.originalFileName}</td>
                                                        <td>{item.title}</td>
                                                        <td style={{ "padding": "0.75rem", "textAlign": "center" }} onClick={() => isMainChange(item.idEquipmentImages)}>
                                                            {item.isMain === '1' &&
                                                                <KTSVG
                                                                    path='/media/icons/duotune/arrows/arr012.svg'
                                                                    className='svg-icon-lg svg-icon-success'
                                                                />
                                                            }
                                                            {item.isMain === '0' &&
                                                                <KTSVG
                                                                    path='/media/icons/duotune/arrows/arr011.svg'
                                                                    className='svg-icon-lg svg-icon-danger'
                                                                />
                                                            }
                                                        </td>
                                                        <td style={{ "textAlign": "center" }}>
                                                            <a href={`${imagePath}/${item.fileName}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-primary">Download</a>
                                                            <button type="button" className="btn btn-sm btn-outline-danger mr-3" onClick={() => removeFile(item.idEquipmentImages)}> <i className="flaticon-delete"></i>Delete</button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            :
                                            <tbody>
                                                <tr>
                                                    <td colSpan="6"><p style={{ "textAlign": "center" }}><strong>No Image Found</strong></p></td>
                                                </tr>
                                            </tbody>
                                        }
                                    </table>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            {/* <pre>{JSON.stringify(imageAttachment, 2)}</pre> */}

        </>
    )
}

export default EquipmentDetails