/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState} from 'react'
import { KTSVG } from '../../_metronic/helpers'
import Error from '../components/Error500'
import GetPagePermission from "../components/GetPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import AsyncSelect from 'react-select/async'
import Swal from 'sweetalert2';
import validationErrorMsg from '../helpers/ValidationErrorMsg';
import { useHistory } from 'react-router-dom'

function VendorCreate() {

  const [formData, setFormData] = useState({
    companyName: '',
    companyBINNumber: '',
    vendorName: '',
    vendorNIDNumber: '',
    vendorContactNumber: '',
    alternativeNumber: '',
    email: '',
    location: '',
    address: '',
    managerName: '',
    managerNumber: '',
    managerNID: '',
    bankACName: '',
    accountNumber: '',
    bankName: '',
    branchName: '',
    mfsAccount: '',
    mfsServiceProvider: '',
    mfsAccountNumber: '',
  });
  const history = useHistory();

  const hasPermission = GetPagePermission("/vendor-add")

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement form submission logic here

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
  }).then((result) => {
      if (result.value) {
            const data = {
            companyName: formData.companyName,
            companyBINNumber: formData.companyBINNumber,
            vendorName: formData.vendorName,
            vendorNIDNumber: formData.vendorNIDNumber,
            vendorContactNumber: formData.vendorContactNumber,
            alternativeNumber: formData.alternativeNumber,
            email: formData.email,
            location: formData.location.value,
            address: formData.address,
            managerName: formData.managerName,
            managerNumber: formData.managerNumber,
            managerNID: formData.managerNID,
            bankACName: formData.bankACName,
            accountNumber: formData.accountNumber,
            bankName: formData.bankName,
            branchName: formData.branchName,
            mfsAccount: formData.mfsAccount,
            mfsServiceProvider: formData.mfsServiceProvider,
            mfsAccountNumber: formData.mfsAccountNumber,
          }

          

          const requestOptions = postRequestOptions(data)
          fetch(`${process.env.REACT_APP_API_URL}vendor_create`, requestOptions)
              .then(response => response.json())
              .then((resp) => {
                if (resp.success === true) {
                    const successMsg = resp.successMessage
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: successMsg,
                    })
                    history.push("/vendor-details/" + resp.data.id_vendor);
                } else {
                    const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        html: errorsMessage,
                    })
                }
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })

 

  }
  })
  };



    const fetchLocationOptions = async (inputValue)=> {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}location_search?search=${inputValue}`, getRequestOptions());
			const data = await response.json();
			const options = data.map((d) => ({
				value: d.id_locations,
				label: d.name,
				name: d.name
			}));

			return options;
		} catch (error) {
			console.error('Error fetching location options:', error);
			return []; // Return an empty array or handle the error case according to your application's logic.
		}
	};


	const handleLocationChange = (selectedOptionl) => {
    setFormData({ ...formData, location: selectedOptionl });

	};

  return (
    <>
    {hasPermission === 1 &&
    <div className="card card-custom">
      <div className="card-header">
        <div className="card-title">
          <span>
            {/* Assuming KTSVG is imported */}
            <KTSVG
              path="/media/svg/icons/Devices/Server.svg"
              className="svg-icon-1 svg-icon-primary"
            />
          </span>
          <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
            Vendor Add
           </span>
        </div>
      </div>
      <div className="ml-12">
        <form onSubmit={handleSubmit}>
          <div className="card-body">
            <div className='row'>
           <div className='col-6'>
             <h4 className='mb-3'>Vendor Details</h4>
            <div className="form-group row">
              <label
                htmlFor="companyName"
                className="col-lg-3 col-form-label"
                style={{ textAlign: 'right' }}
              >
                {' '}
                Vendor Name <span className="required text-danger"></span>
              </label>
              <div className="col-lg-5">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="companyName"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-group row">
                <label htmlFor="companyBINNumber" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}>BIN Number</label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="companyBINNumber" name="companyBINNumber" value={formData.companyBINNumber} onChange={handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="vendorName" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}>Proprietor/Business Owner Name<span className="required text-danger"></span> </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="vendorName" name="vendorName" value={formData.vendorName} onChange={handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="vendorNIDNumber" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}>NID Number </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="vendorNIDNumber" name="vendorNIDNumber" value={formData.vendorNIDNumber} onChange={handleChange} />
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="vendorContactNumber" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}>Owner Contact Number <span className="required text-danger"></span> </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="vendorContactNumber" name="vendorContactNumber" value={formData.vendorContactNumber} onChange={handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="alternativeNumber" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}>Owner Alternative Number </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="alternativeNumber" name="alternativeNumber" value={formData.alternativeNumber} onChange={handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="email" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}>Owner Email </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="email" name="email" value={formData.email} onChange={handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="location" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}> Location <span className="required text-danger"></span> </label>
                <div className="col-lg-5">
                <AsyncSelect
									name="location"
									value={formData.location}
									defaultOptions
									loadOptions={fetchLocationOptions}
									placeholder="Select location"
									onChange={handleLocationChange}
								/>
                </div>
            </div>

            <div className="form-group row">
                <label htmlFor="address" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}> Address <span className="required text-danger"></span> </label>
                <div className="col-lg-5">
                    <textarea className="form-control form-control-sm" id="address" name="address" value={formData.address} onChange={handleChange} />
                </div>
            </div>

            </div>

            <div className='col-6'>

       
            <div className="form-group row">
                <label htmlFor="managerName" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}> Manager Name  </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="managerName" name="managerName" value={formData.managerName} onChange={handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="managerNumber" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}> Manager Number </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="managerNumber" name="managerNumber" value={formData.managerNumber} onChange={handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="managerNID" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}> Manager NID  </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="managerNID" name="managerNID" value={formData.managerNID} onChange={handleChange} />
                </div>
            </div>

            </div>
            </div>
          
            <div className='col-6'>
              <h4>Bank Details</h4>
            <div className="form-group row">
                <label htmlFor="bankACName" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}> Bank AC Name  </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="bankACName" name="bankACName" value={formData.bankACName} onChange={handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="accountNumber" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}> Account Number  </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="accountNumber" name="accountNumber" value={formData.accountNumber} onChange={handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="bankName" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}> Bank Name </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="bankName" name="bankName" value={formData.bankName} onChange={handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="branchName" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}> Branch Name </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="branchName" name="branchName" value={formData.branchName} onChange={handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="mfsAccount" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}> MFS Account </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="mfsAccount" name="mfsAccount" value={formData.mfsAccount} onChange={handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="mfsServiceProvider" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}> MFS Service Provider </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="mfsServiceProvider" name="mfsServiceProvider" value={formData.mfsServiceProvider} onChange={handleChange} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="mfsAccountNumber" className="col-lg-3 col-form-label" style={{ textAlign: 'right' }}> MFS Account Number </label>
                <div className="col-lg-5">
                    <input type="text" className="form-control form-control-sm" id="mfsAccountNumber" name="mfsAccountNumber" value={formData.mfsAccountNumber} onChange={handleChange} />
                </div>
            </div>
            </div>
            

            {/* Add more form fields for vendor details */}
            
            <div className="row mt-2">
                               
                                <div className='col-6 ' style={{'textAlign':'center'}}>
                                    <button className="btn btn-sm btn-primary me-2" type='submit'>
                                        Submit
                                    </button>
                                </div>
                </div>

          </div>
        </form>
      </div>
    </div>
    }
    {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === '' &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }
    </>

  )
}

export default VendorCreate;
