import React, { useState } from 'react'
import DataTable from '../components/DataTable'
import { KTSVG } from '../../_metronic/helpers'
import CategoryCreate from '../components/CategoryCreate'
import Error from '../components/Error500'
import GetPagePermission from "../components/GetPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function Categories() {

    const hasPermission = GetPagePermission("/setup-categories");
    const [newData, setNewData] = useState('');
    const [id, setId] = useState('');

    const onCreate = (data) => {
        setNewData({ id: data });
        setId('');
    }

    const columns = [
        { value: 'id', title: 'Id', style: { textAlign: 'center' } },
        { value: 'categoryName', title: 'Category Name', style: { textAlign: 'left' } },
        {
            value: 'status', title: 'Status', style: { textAlign: 'center' }, hasHtmlComponent: true, htmlComponent: ((row) => {
                return (
                    <>
                        {row.status === 'active' && <span className="badge badge-light-primary">Active</span>}
                        {row.status === 'inactive' && <span className="badge badge-light-danger">Inactive</span>}
                    </>
                )
            })
        },
        {
            value: 'fileName', title: 'Image', style: { textAlign: 'center' }, hasFilterValue: false, hasActionsComponent: true, actionsComponent: ((row) => {
                return (
                    <>
                        <img src={process.env.REACT_APP_AWS_URL + "toolpati/category/" + row.id + "/" + row.fileName} alt="pic" style={{ width: "140px", height: "80px" }} />
                    </>
                )
            })

        },
        {
            value: 'action', title: 'Action', style: { textAlign: 'center' }, hasActionsComponent: true, actionsComponent: ((row) => {
                return (
                    <>
                        <button className="btn btn-light-primary btn-sm" style={{ whiteSpace: 'nowrap' }} onClick={() => onAction(row)}>
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1 svg-icon-3' />
                            Edit
                        </button>
                    </>)

            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        categoryName: {
            filterType: 'like',
            filterValue: '',
        },
        status: {
            filterType: 'like',
            filterValue: '',
        }

    }

    const url = process.env.REACT_APP_API_URL + 'category_dt'

    const onAction = (data) => {
        setId(data.id);
    }

    return (
        <>
            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <KTSVG
                                path='/media/svg/icons/Files/File-plus.svg'
                                className='svg-icon-1 svg-icon-primary'
                            />
                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                Add new Category
                            </span>
                        </div>
                    </div>

                    <div className='card-body'>
                        <CategoryCreate onCreate={onCreate} id={id} />
                        <div className="card-header">
                            <div className="card-title">
                                <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                                <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                    Category List
                                </span>
                            </div>
                        </div>
                        <div className='card-body'>
                            <DataTable url={url} columns={columns} filters={filters} newData={newData} />
                        </div>
                    </div>
                </div>
            }
            {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === '' &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }
        </>
    )
}

export default Categories