/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from "../helpers/Fetchwrapper";
import validationErrorMsg from "../helpers/ValidationErrorMsg";

function UserCreate(props) {
	const [id, setId] = useState('');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [status, setStatus] = useState('active');

	const myRef = useRef();

	useEffect(() => {
		if (id !== props.id || name !== props.email) {
			setId(props.id);
			getUserDetails(props.id);
			window.scrollTo(0, 0);
			myRef.current.focus();
		}
	}, [props.id, props.email]);

	const getUserDetails = (userId) => {
		fetch(process.env.REACT_APP_API_URL + "user_details/" + userId,
			getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setName(resp.name);
				setEmail(resp.email);
				setPassword(resp.prePassword);
				setConfirmPassword(resp.prePassword);
				setPhoneNumber(resp.phoneNumber);
				setStatus(resp.status);
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}


	const statusType = [{ label: 'active', value: 'active' }, { label: 'inactive', value: 'inactive' }]


	const handleSubmit = (evt) => {
		evt.preventDefault()
		if (id !== '') {
			var text = 'You want to update this User!'
		} else {
			text = 'You want to add this User!'
		}
		Swal.fire({
			title: 'Are you sure?',
			text: text,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
		}).then((result) => {
			if (result.value) {
				const formData = {
					id: id,
					name: name,
					email: email,
					password: password,
					password_confirmation: confirmPassword,
					phoneNumber: phoneNumber,
					status: status
				}

				const requestOptions = postRequestOptions(formData);

				if (id !== '') {
					var apiEnd = 'user_update'
				} else {
					apiEnd = 'user_create'
				}

				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						console.log(resp)

						if (resp.success === true) {
							if (id !== '') {
								var successMsg = [`User ${resp.data.email} updated`]
							} else {
								successMsg = [`User ${resp.data.email} added`]
							}

							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: successMsg,
							})
							setId('');
							setName('');
							setEmail('');
							setPassword('');
							setConfirmPassword('');
							setPhoneNumber('');
							props.onCreate({ id: resp.data.id, name: resp.data.email })
						} else {

							const errorsMessage = validationErrorMsg(resp.errors).join("<br>");
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: errorsMessage,
							})
						}
					})
					.catch((error) => {
						console.log(error, 'catch the hoop')
					})
			}
		})
	}

	return (
		<div className='card-body'>
			<div className='ml-12' >
				<form onSubmit={handleSubmit}>
					<div className='form-group row'>
						<label
							htmlFor='name'
							className='col-lg-3 col-form-label'
							style={{ textAlign: 'right' }}
						>
							Name<span className='required text-danger'></span>
						</label>
						<div className='col-lg-5'>
							<input
								type='text'
								name='name'
								id='name'
								ref={myRef}
								value={name}
								onChange={(e) => setName(e.target.value)}
								className='form-control form-control-sm'
							/>
						</div>
					</div>

					<div className='form-group row'>
						<label
							htmlFor='email'
							className='col-lg-3 col-form-label'
							style={{ textAlign: 'right' }}
						>
							Email<span className='required text-danger'></span>
						</label>
						<div className='col-lg-5'>
							<input
								type='email'
								name='email'
								id='email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								readOnly={id !== ''}
								className='form-control form-control-sm'
							/>
						</div>
					</div>

					<div className='form-group row'>
						<label
							htmlFor='password'
							className='col-lg-3 col-form-label'
							style={{ textAlign: 'right' }}
						>
							Password<span className='required text-danger'></span>
						</label>
						<div className='col-lg-5'>
							<input
								type='password'
								name='password'
								id='password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								className='form-control form-control-sm'
							/>
						</div>
					</div>

					<div className='form-group row'>
						<label
							htmlFor='confirmPassword'
							className='col-lg-3 col-form-label'
							style={{ textAlign: 'right' }}
						>
							Confirm Password<span className='required text-danger'></span>
						</label>
						<div className='col-lg-5'>
							<input
								type='password'
								name='confirmPassword'
								id='confirmPassword'
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								className='form-control form-control-sm'
							/>
						</div>
					</div>

					<div className='form-group row'>
						<label
							htmlFor='phoneNumber'
							className='col-lg-3 col-form-label'
							style={{ textAlign: 'right' }}
						>
							Phone Number
						</label>
						<div className='col-lg-5'>
							<input
								type='text'
								name='phoneNumber'
								id='phoneNumber'
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
								className='form-control form-control-sm'
							/>
						</div>
					</div>


					<div className="form-group row">
						<label htmlFor="status" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} >Status<span className="required text-danger"></span></label>
						<div className="col-lg-5">
							<select className="form-select form-select-sm" id="status" name="status" value={status} onChange={(e) => setStatus(e.target.value)} >
								{statusType.map(function (item, id) {
									return <option key={id} value={item.value}>{item.label}</option>
								})
								}
							</select>
						</div>
					</div>


					<div className='row'>
						<div className='col-4'></div>
						<div className='col-6'>
							<input
								type='submit'
								className='btn btn-sm btn-primary mt-2'
								value={id !== '' ? 'Update' : 'Save'}
							/>

						</div>
					</div>
					{/* <pre>
            {JSON.stringify(name, null, 2)}
            {JSON.stringify(status, null, 2)}
          </pre> */}
				</form>
			</div>
		</div>
	)
}

export default UserCreate
