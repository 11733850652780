import React from 'react'
import DataTable from '../components/DataTable'
import { KTSVG } from '../../_metronic/helpers'
import Error from '../components/Error500'
import GetPagePermission from "../components/GetPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from "react-router-dom";

function VendorList() {

    const hasPermission = GetPagePermission("/vendor-list");

    const columns = [
        { value: 'id', title: 'Id', style: { textAlign: 'center' } },
        { value: 'companyName', title: 'Company Name', style: { textAlign: 'left' } },
        { value: 'vendorName', title: 'Vendor Name', style: { textAlign: 'left' } },
        { value : 'vendorContactNumber', title: 'Mobile', style: { textAlign: 'left' } },
        { value : 'email', title: 'Email', style: { textAlign: 'left' } },
        { value : 'location', title: 'Location', style: { textAlign: 'left' } },
        { value : 'totalEquipment', title: 'Total Equipments', style: { textAlign: 'left' } },
        {
            value: 'action', title: 'Action', style: { textAlign: 'center', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row) => {
                return (
                    <>
                        
                        <Link to={`/vendor-details/${row.id}`}>
                            <button className="btn btn-light-primary btn-sm">
                                <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-3' />
                                Details
                            </button>
                        </Link>
                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        companyName: {
            filterType: 'like',
            filterValue: '',
        },
        vendorName: {
            filterType: 'like',
            filterValue: '',
        },
        vendorContactNumber: {
            filterType: 'like',
            filterValue: '',
        },
        email: {
            filterType: 'like',
            filterValue: '',
        },
        location: {
            filterType: 'like',
            filterValue: '',
        },
        totalEquipment: {
            filterType: 'like',
            filterValue: '',
        }
    }

    const url = process.env.REACT_APP_API_URL + 'vendor_list'

    return (
        <>
            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header'>
                        <div className="card-title">
                            <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                Vendor List
                            </span>
                        </div>
                    </div>

                    <div className='card-body'>
                        <DataTable url={url} columns={columns} filters={filters} />
                    </div>
                </div>
            }

            {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === '' &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }
        </>
    )
}

export default VendorList