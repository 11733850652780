/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import { postRequestOptions,getRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Spinner from 'react-bootstrap/Spinner'

export default function PaymentReceive({ show, handleClose, idQuotation }) {

    const [amount, setAmount] = useState(null);
    const [paidAmount, setPaidAmount] = useState(null);
    const [paymentDate, setPaymentDate] = useState(new Date().toISOString().slice(0, 10));
    const [note, setNote] = useState(null);
    const [isSubmitDisable, setSubmitDisable] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {   
        getPaidAmount();
    }, [idQuotation])
    

    const paymentReceiveSubmit = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Receive it!'
        }).then((result) => {
            if (result.value) {
                setSubmitDisable(true);
                setLoading(true);
                const formData = {
                    idQuotation: idQuotation,
                    amount: amount,
                    paymentDate: paymentDate,
                    note: note
                }
                const requestOptions = postRequestOptions(formData)

                fetch(process.env.REACT_APP_API_URL + 'payment_receive', requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        setLoading(false);
                        setSubmitDisable(false);
                        if (resp.success === true) {
                            const successMsg = resp.successMessage
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })
                            getPaidAmount();
                            setAmount(null);
                            setPaymentDate(new Date().toISOString().slice(0, 10));
                            setNote(null);
                            handleClose(true)
                        } else {
                            const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        setSubmitDisable(false);
                        console.log(error, 'catch the hoop')
                    })
            }
        })

    }

    const getPaidAmount = () => {
        

        fetch(process.env.REACT_APP_API_URL + 'get_paid_amount/'+idQuotation, getRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setPaidAmount(resp.data);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    return (
        <Modal show={show} onHide={() => handleClose(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Payment Receive</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <div className='card-body'>

                    <div className='ml-12'>

                    <div className='form-group row'>
                            <label htmlFor='paidAmount' className='col-4 col-form-label'>
                                Paid Amount
                            </label>
                            <div className='col-6'>
                                <input
                                    type='text'
                                    name='paidAmount'
                                    id='paidAmount'
                                    value={paidAmount}
                                    readOnly
                                    className='form-control form-control-sm'
                                />
                            </div>
                    </div>

                    {/* paymnet date*/}
                    <div className='form-group row'>
                            <label htmlFor='paymentDate' className='col-4 col-form-label'>
                                Payment Date <span className='text-danger'>*</span>
                            </label>
                            <div className='col-6'>
                                <input
                                    type="date"
                                    name='paymentDate'
                                    id='paymentDate'
                                    onChange={(e) => setPaymentDate(e.target.value)}
                                    value={paymentDate || ''}
                                    className='form-control form-control-sm'
                                />

                            </div>
                        </div>
                        


                    <div className='form-group row'>
                            <label htmlFor='amount' className='col-4 col-form-label'>
                                Amount <span className='text-danger'>*</span>
                            </label>
                            <div className='col-6'>
                                <input
                                    type='text'
                                    name='amount'
                                    id='amount'
                                    value={amount || ''}
                                    onChange={(e) => setAmount(e.target.value)}
                                    className='form-control form-control-sm'
                                />
                            </div>
                        </div>
                        
                       
                        <div className='form-group row'>
                            <label htmlFor='note' className='col-4 col-form-label'>
                                Note
                            </label>
                            <div className='col-6'>
                                <textarea
                                    name='note'
                                    id='note'
                                    value={note || ''}
                                    onChange={(e) => setNote(e.target.value)}
                                    className='form-control form-control-sm'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant='primary' onClick={paymentReceiveSubmit} size="sm">
            Complete
        </Button> */}
                <button disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2" onClick={paymentReceiveSubmit}>
                    {loading &&
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                    <span>  Receive</span>
                </button>

                <Button variant='secondary' onClick={() => handleClose(false)} size="sm">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
