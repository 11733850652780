/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, { FC, useState, useEffect, useContext } from 'react'
import { KTSVG } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useLayout } from '../../core'
import { AppContext } from '../../../../app/context/AppContext';


const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
	toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
	const { currentUser } = useContext(AppContext);
	const { config } = useLayout()

	const [pic, setPic] = useState(null);
	const [fullname, setFullname] = useState(null);

	useEffect(() => {
		// const getUserInfo = async () => {
		// 	try {
		// 		const requestOptions = getRequestOptions();
		// 		const response = await fetch(`${process.env.REACT_APP_API_URL}users/get_user_info`, requestOptions);
		// 		const resp = await response.json();
		// 		setUserInfo(resp.data);
		// 		setFullname(resp.data.fullName);
		// 		setPic(resp.data.fileName);
		// 	}
		// 	catch (error) {
		// 		const removeJwt = () => { return localStorage.removeItem('MyToken'); }
		// 		removeJwt();
		// 	}
		// }
		// getUserInfo();
		if (currentUser !== undefined) {
			setFullname(currentUser.fullName);
			setPic(currentUser.fileName);
		}
	}, [currentUser]);

	return (
		<div className='d-flex align-items-stretch flex-shrink-0'>


			{/* begin::User */}
			<div
				className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
				id='kt_header_user_menu_toggle'
			>
				{/* begin::Toggle */}
				<div
					className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
					data-kt-menu-trigger='click'
					data-kt-menu-attach='parent'
					data-kt-menu-placement='bottom-end'
					data-kt-menu-flip='bottom'
				>
					{(fullname !== null && pic !== 'default.jpg') &&
						<>
							<img src={`${process.env.REACT_APP_AWS_URL}brac/profile_picture/${pic}`} alt='user' /> <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3" style={{ color: "rgb(255, 255, 255)" }}>{fullname}</span>
						</>
					}

					{(fullname !== null && pic === "default.jpg") &&
						<>
							<img src={`media/avatars/${pic}`} alt='user' /> <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3" style={{ color: "rgb(255, 255, 255)" }}>{fullname}</span>
						</>
					}

				</div>
				<HeaderUserMenu />
				{/* end::Toggle */}
			</div>
			{/* end::User */}

			{/* begin::Aside Toggler */}
			{config.header.left === 'menu' && (
				<div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
					<div
						className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
						id='kt_header_menu_mobile_toggle'
					>
						<KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
					</div>
				</div>
			)}
		</div>
	)
}

export { Topbar }