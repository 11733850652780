/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import Error from '../components/Error500'
import GetPagePermission from "../components/GetPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import Swal from 'sweetalert2';
import validationErrorMsg from '../helpers/ValidationErrorMsg';
import { useHistory } from 'react-router-dom'
import { Editor } from '@tinymce/tinymce-react'

function EquipmentCreate() {

    const initialState = () => ({
        vendor: '',
        category: '',
        categoryEquipment: '',
        brand: '',
        model: '',
        capacity: '',
        operatingWeight: '',
        yearOfManufacture: '',
        rentRate: '',
        description: '',
        otherSize: '',
        yardLocation: '',
        operatorFooding: '',
    })
    const hasPermission = GetPagePermission("/equipment-create");
    const [formData, setFormData] = useState(initialState());
    const history = useHistory();
    const [categoryEquipmentOptions, setCategoryEquipmentOptions] = useState([]);
    const editorRef = useRef(null);

    useEffect(() => {
        if (formData.category !== '') {
            getCategoryEquipments(formData.category.value);
        }
    }, [formData.category]);



    const getAllVendor = (inputValue, callback) => {

        if (!inputValue) {
            var url = process.env.REACT_APP_API_URL + "get_all_vendors";
        }
        else {
            url = process.env.REACT_APP_API_URL + "get_all_vendors?q=" + inputValue;
        }

        setTimeout(() => {
            fetch(url,
                getRequestOptions())

                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    const tempArray = resp.data.map(function (element) {
                        element.label = element.vendorName;
                        element.value = element.idVendor;
                        return element;
                    });
                    callback(tempArray);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        });
    }



    const getAllCategories = (inputValue, callback) => {

        if (!inputValue) {
            var url = process.env.REACT_APP_API_URL + "get_all_categories";

        }
        else {

            url = process.env.REACT_APP_API_URL + "get_all_categories?q=" + inputValue;
        }

        setTimeout(() => {
            fetch(url,
                getRequestOptions())

                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    const tempArray = resp.data.map(function (element) {
                        element.label = element.categoryName;
                        element.value = element.idCategory;
                        return element;
                    });
                    callback(tempArray);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        });
    }

    const getAllBrands = (inputValue, callback) => {

        if (!inputValue) {
            var url = process.env.REACT_APP_API_URL + "get_all_brands";

        }
        else {

            url = process.env.REACT_APP_API_URL + "get_all_brands?q=" + inputValue;
        }

        setTimeout(() => {
            fetch(url,
                getRequestOptions())

                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    const tempArray = resp.data.map(function (element) {
                        element.label = element.brandName;
                        element.value = element.idBrand;
                        return element;
                    });
                    callback(tempArray);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        });
    }

    const getCategoryEquipments = (categoryId) => {

        var url = process.env.REACT_APP_API_URL + "get_all_category_equipments/" + categoryId;

        fetch(url,
            getRequestOptions())

            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                const tempArray = resp.data.map(function (element) {
                    element.label = element.equipmentName;
                    element.value = element.idCategoryEquipment;
                    return element;
                });
                tempArray.push({ label: 'Others', value: 'others' });
                setCategoryEquipmentOptions(tempArray);
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }



    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleCategoryChange = (category) => {
        setFormData({ ...formData, category: category, categoryEquipment: '' });
    }

    const handleBrandChange = (brand) => {
        setFormData({ ...formData, brand: brand });
    }

    const handleCategoryEquipmentChange = (categoryEquipment) => {
        setFormData({ ...formData, categoryEquipment: categoryEquipment });
    }

    const handleVendorChange = (vendor) => {
        setFormData({ ...formData, vendor: vendor });
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.value) {
                const data = {
                    vendor: formData.vendor,
                    category: formData.category,
                    categoryEquipment: formData.categoryEquipment,
                    brand: formData.brand,
                    model: formData.model,
                    capacity: formData.capacity,
                    operatingWeight: formData.operatingWeight,
                    yearOfManufacture: formData.yearOfManufacture,
                    rentRate: formData.rentRate,
                    description: editorRef.current.getContent(),
                    otherSize: formData.otherSize,
                    yardLocation: formData.yardLocation,
                    operatorFooding: formData.operatorFooding,
                }
                const requestOptions = postRequestOptions(data)

                fetch(process.env.REACT_APP_API_URL + 'equipment_create', requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            const successMsg = resp.successMessage
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })
                            history.push("/equipment-details/" + resp.data.id_equipment);
                        } else {
                            const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, 'catch the hoop')
                    })
            }
        })
    }
    return (
        <>
            {hasPermission === 1 &&
                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <span>
                                <KTSVG
                                    path='/media/svg/icons/Devices/Server.svg'
                                    className='svg-icon-1 svg-icon-primary'
                                />
                            </span>
                            <span className="text fw-bolder" style={{ marginLeft: "10px" }}>
                                Equipment Create
                            </span>
                        </div>
                    </div>

                    <div className="ml-12">
                        <form onSubmit={handleSubmit}>
                            <div className="card-body">

                                <div className="form-group row">
                                    <label htmlFor="category" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Vendor <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <AsyncSelect
                                            value={formData.vendor}
                                            loadOptions={getAllVendor}
                                            placeholder="Select Vendor"
                                            onChange={handleVendorChange}
                                            menuPosition='fixed'
                                            defaultOptions

                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="category" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Category <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <AsyncSelect
                                            value={formData.category}
                                            loadOptions={getAllCategories}
                                            placeholder="Select Category"
                                            onChange={handleCategoryChange}
                                            menuPosition='fixed'
                                            defaultOptions

                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="category" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Equipment Size <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <Select
                                            value={formData.categoryEquipment}
                                            options={categoryEquipmentOptions}
                                            placeholder="Select Equipment Size"
                                            onChange={handleCategoryEquipmentChange}
                                            isDisabled={formData.category === ''}
                                            menuPosition='fixed'
                                        />
                                    </div>
                                </div>
                                {formData.categoryEquipment !== '' && formData.categoryEquipment.value === 'others' &&
                                    <div className="form-group row">
                                        <label htmlFor="otherSize" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Other Size </label>
                                        <div className="col-lg-5">
                                            <input type="text" name="otherSize" id="otherSize" value={formData.otherSize} onChange={handleInputChange} className="form-control form-control-sm" />
                                        </div>
                                    </div>
                                }
                                <div className="form-group row">
                                    <label htmlFor="brand" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Brand <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <AsyncSelect
                                            value={formData.brand}
                                            loadOptions={getAllBrands}
                                            placeholder="Select Brand"
                                            onChange={handleBrandChange}
                                            menuPosition='fixed'
                                            defaultOptions
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="model" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Model <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type="text" name="model" id="model" value={formData.model} onChange={handleInputChange} className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="capacity" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Capacity </label>
                                    <div className="col-lg-5">
                                        <input type="text" name="capacity" id="capacity" value={formData.capacity} onChange={handleInputChange} className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="operatingWeight" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Operating Weight </label>
                                    <div className="col-lg-5">
                                        <input type="text" name="operatingWeight" id="operatingWeight" value={formData.operatingWeight} onChange={handleInputChange} className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="yearOfManufacture" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Manufacturing Year <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type="number" name="yearOfManufacture" id="yearOfManufacture" value={formData.yearOfManufacture} onChange={handleInputChange} className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="rentRate" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Rent Rate <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type="number" name="rentRate" id="rentRate" value={formData.rentRate} onChange={handleInputChange} className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="operatorFooding" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Operator Salary & Fooding (8h/day)</label>
                                    <div className="col-lg-5">
                                        <input type="number" name="operatorFooding" id="operatorFooding" value={formData.operatorFooding} onChange={handleInputChange} className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="yardLocation" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Yard Location <span className="required text-danger"></span></label>
                                    <div className="col-lg-5">
                                        <input type="text" name="yardLocation" id="yardLocation" value={formData.yardLocation} onChange={handleInputChange} className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="description" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Description </label>
                                    <div className="col-lg-6">
                                        <Editor
                                            apiKey="27k7mo6dhwbg8ogpsyq0gfjtfd4d5682zmurtqp44ean979x"
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            init={{
                                                height: 500,
                                                plugins: [
                                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                    'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                                ],
                                                toolbar: 'undo redo | blocks | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'

                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-5"></div>
                                <div className='col-6'>
                                    <button className="btn btn-sm btn-primary me-2" type='submit'>
                                        Submit
                                    </button>
                                </div>
                            </div>
                            {/* <pre>
                                {JSON.stringify(formData, null, 2)}
                            </pre> */}
                        </form>
                    </div>
                </div>
            }
            {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === '' &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }
        </>
    )
}

export default EquipmentCreate