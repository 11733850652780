import React, { useState, useEffect, createContext } from "react";
import { getRequestOptions } from "../helpers/Fetchwrapper";

export const userPermissionContext = createContext();

const UserPagePermission = (props) => {

	const [loading, setLoading] = useState(false);
	const [pageList, setPageList] = useState([]);
	const [pagesLink, setPagesLink] = useState([]);

	useEffect(() => {
		const token = JSON.parse(localStorage.getItem("MyToken")) ? JSON.parse(localStorage.getItem("MyToken")) : null;
		if (token == null) return;
		getUserPages();
		getUserPagesLink();
	}, []);

	const getUserPages = () => {
		setLoading(true);

		fetch(process.env.REACT_APP_API_URL + "get_user_pages", getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setLoading(false);
				const menu = resp.data.map(function (row) {
					return { ...row, totalSubMenu: row.subMenu.length }
				});
				setPageList(menu);
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});

	}

	const getUserPagesLink = () => {

		fetch(process.env.REACT_APP_API_URL + "get_user_pages_link", getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setPagesLink(resp.data);
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});

	}

	const jwt = JSON.parse(localStorage.getItem('MyToken'));
	const value = { pageList, loading, pagesLink, jwt };
	return (
		<userPermissionContext.Provider value={value}>
			{props.children}
		</userPermissionContext.Provider>
	)
}
export default UserPagePermission