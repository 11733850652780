import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './app/App'
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import './custom-style.css'

const { PUBLIC_URL } = process.env

ReactDOM.render(
	<MetronicI18nProvider>
		<App basename={PUBLIC_URL} />
	</MetronicI18nProvider>,
	document.getElementById('root')
)
