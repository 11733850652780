import React from 'react'
import DataTable from '../components/DataTable'
import { KTSVG } from '../../_metronic/helpers'
import Error from '../components/Error500'
import GetPagePermission from "../components/GetPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from "react-router-dom";

function EquipmentList() {

    const hasPermission = GetPagePermission("/equipment-list");

    const columns = [
        { value: 'id', title: 'Id', style: { textAlign: 'center' } },
        { value: 'categoryName', title: 'Category', style: { textAlign: 'left' } },
        { value: 'equipmentName', title: 'Equipment', style: { textAlign: 'left' } },
        { value: 'brandName', title: 'Brand', style: { textAlign: 'left' } },
        { value: 'model', title: 'Model', style: { textAlign: 'left' } },
        { value: 'capacity', title: 'Capacity', style: { textAlign: 'left' } },
        { value: 'operatingWeight', title: 'Operating Weight', style: { textAlign: 'left' } },
        { value: 'yearOfManufacture', title: 'Manufacturing year', style: { textAlign: 'left' } },
        { value: 'yardLocation', title: 'Yard Location', style: { textAlign: 'left' } },
        { value: 'vendor', title: 'Vendor', style: { textAlign: 'left' } },
        {
            value: 'status', title: 'Status', style: { textAlign: 'left' }, hasHtmlComponent: true, htmlComponent: ((row) => {

                return (
                    <>
                        {row.status === 'active' ? <span className="badge badge-light-success fw-bolder">Active</span> : <span className="badge badge-light-danger fw-bolder">Inactive</span>}
                    </>
                )
            })
        },
        {
            value: 'action', title: 'Action', style: { textAlign: 'center', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row) => {
                return (
                    <>
                        <Link to={`/equipment-edit/${row.id}`}>
                            <button className="btn btn-light-primary btn-sm" style={{ marginRight: '5px' }}>
                                <KTSVG path='/media/svg/icons/Communication/Write.svg' className='svg-icon-1 svg-icon-3' />
                                Edit
                            </button>
                        </Link>
                        <Link to={`/equipment-details/${row.id}`}>
                            <button className="btn btn-light-primary btn-sm">
                                <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-3' />
                                Details
                            </button>
                        </Link>
                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        categoryName: {
            filterType: 'like',
            filterValue: '',
        },
        equipmentName: {
            filterType: 'like',
            filterValue: '',
        },
        brandName: {
            filterType: 'like',
            filterValue: '',
        },
        model: {
            filterType: 'like',
            filterValue: '',
        },
        capacity: {
            filterType: 'like',
            filterValue: '',
        },
        operatingWeight: {
            filterType: 'like',
            filterValue: '',
        },
        yearOfManufacture: {
            filterType: 'like',
            filterValue: '',
        },
        yardLocation: {
            filterType: 'like',
            filterValue: '',
        },
        vendor: {
            filterType: 'like',
            filterValue: '',
        },
        status: {
            filterType: 'like',
            filterValue: '',
        },

    }

    const url = process.env.REACT_APP_API_URL + 'equipment_list'

    return (
        <>
            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header'>
                        <div className="card-title">
                            <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                Equipment List
                            </span>
                        </div>
                    </div>

                    <div className='card-body'>
                        <DataTable url={url} columns={columns} filters={filters} />
                    </div>
                </div>
            }

            {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === '' &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }
        </>
    )
}

export default EquipmentList