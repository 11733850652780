import React, {  useState } from "react";
import DataTable from '../components/DataTable'
import { KTSVG } from '../../_metronic/helpers'
import Error from '../components/Error500'
import GetPagePermission from "../components/GetPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from "react-router-dom";
import PaymentReceive from "../components/PaymentReceive"

function QuotationList() {

    const [showPaymentReceiveModal, setShowPaymentReceiveModal] = useState(false);
    const [idQuotation, setIdQuotation] = useState('');
    const [paymentReceiveData, setPaymentReceiveData] = useState('');
    const hasPermission = GetPagePermission("/quotation-list");

    const onPayment = (data) => {
        console.log(data);
        setIdQuotation(data.id);
        setShowPaymentReceiveModal(true);
	}


    const handlePaymnetModalClose = (hasPayment) => {
        setShowPaymentReceiveModal(false);
        if(hasPayment){
              
              setPaymentReceiveData({id:idQuotation + Math.random()});
        }
    }

    const columns = [
        { value: 'id', title: 'Id', style: { textAlign: 'center' } },
        { value: 'customerName', title: 'Customer', style: { textAlign: 'left' } },
        { value: 'totalEquipment', title: 'Total No Of Equipment', style: { textAlign: 'center' } },
        { value: 'quotationDate', title: 'Quotation Date', style: { textAlign: 'left' } },
        { value: 'totalAmount', title: 'Grand Total', style: { textAlign: 'left' } },
        { value: 'paymentStatus', title: 'Payment Status', style: { textAlign: 'left' },hasHtmlComponent: true,
         htmlComponent: ((row) => {
                
                return (
                    <>
                        {row.paymentStatus === 'Paid' && <span className="badge badge-success fw-bolder">Paid</span>}
                        {row.paymentStatus === 'Due' && <span className="badge badge-danger fw-bolder">Due</span>}
                    </>
                )}
        ) },
        { value: 'paymentDue', title: 'Payment Due', style: { textAlign: 'left' } },
    
        {
            value: 'status', title: 'Status', style: { textAlign: 'left' }, hasHtmlComponent: true, htmlComponent: ((row) => {

                return (
                    <>
                        {row.status === 'pending_for_approval' && <span className="badge badge-primary fw-bolder">Pending For Approval</span>}
                        {row.status === 'approved' && <span className="badge badge-secondary fw-bolder">Approved</span>}
                        {row.status === 'quotation_offered' && <span className="badge badge-info fw-bolder">Quotation Offered</span>}
                        {row.status === 'quotation_accepted' && <span className="badge badge-success fw-bolder">Quotation Accepted</span>}
                        {row.status === 'quotation_reviewed' && <span className="badge badge-warning fw-bolder">Quotation ask for Review</span>}
                        {row.status === 'quotation_declined' && <span className="badge badge-danger fw-bolder">Quotation Declined</span>}
                        {row.status === 'rent_confirmed' && <span className="badge badge-primary fw-bolder">Rent Confirmed</span>}
                        {row.status === 'rental_in_progress' && <span className="badge badge-primary fw-bolder">Rental in Progress</span>}
                        {row.status === 'completed' && <span className="badge badge-success fw-bolder">Rental Completed</span>}
                        {row.status === 'cancelled' && <span className="badge badge-danger fw-bolder">Rental Cancelled</span>}
                    </>
                )
            })
        },
        {
            value: 'action', title: 'Action', style: { textAlign: 'center', whiteSpace: 'nowrap' }, hasActionsComponent: true, actionsComponent: ((row) => {
                return (
                    <>
                        <Link to={`/quotation-details/${row.id}`}>
                            <button className="btn btn-light-primary btn-sm" style={{ marginRight: '5px' }}>
                                <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-3' />
                                
                            </button>
                        </Link>
                        
                        <button className="btn btn-light-primary btn-sm" style={{ marginRight: '5px' }} onClick={() => onPayment(row)}> 
                            {/* payment svg icon */}
                            <KTSVG path='/media/svg/icons/Shopping/Wallet.svg' className='svg-icon-1 svg-icon-3' />
                           
                            
                        </button>

                        <Link to={`/quotation-print/${row.id}`}>
                            <button className="btn btn-light-primary btn-sm" style={{ marginRight: '5px' }}>
                                <KTSVG path='/media/svg/icons/Devices/Printer.svg' className='svg-icon-1 svg-icon-3' />
                               
                            </button>
                        </Link>

                        

                    </>
                )
            })
        }
    ]

    const filters = {
        id: {
            filterType: 'like',
            filterValue: '',
        },
        customerName: {
            filterType: 'like',
            filterValue: '',
        },
        totalEquipment: {
            filterType: 'like',
            filterValue: '',
        },
        totalAmount: {
            filterType: 'like',
            filterValue: '',
        },
        quotationDate: {
            filterType: 'like',
            filterValue: '',
        },
        paymentStatus: {
            filterType: 'like',
            filterValue: '',
        },
        paymentDue: {
            filterType: 'like',
            filterValue: '',
        },
        status: {
            filterType: 'like',
            filterValue: '',
        }

    }

    const url = process.env.REACT_APP_API_URL + 'quotation_list'

    return (
        <>
            {hasPermission === 1 &&
                <div className='card card-custom'>
                    <div className='card-header'>
                        <div className="card-title">
                            <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                                Quotation List
                            </span>
                        </div>
                    </div>

                    <div className='card-body'>
                        <DataTable url={url} columns={columns} filters={filters}  newData={paymentReceiveData} />
                    </div>
                </div>
            }

            {hasPermission === 0 &&
                <Error />
            }
            {hasPermission === '' &&
                <div className='card card-custom' style={{ padding: "15px" }} >
                    <SkeletonTheme>
                        <p>
                            <Skeleton
                                containerClassName="avatar-skeleton"
                                count={40} ></Skeleton>
                        </p>
                    </SkeletonTheme>
                </div>
            }
             <PaymentReceive show={showPaymentReceiveModal} handleClose={handlePaymnetModalClose}  idQuotation={idQuotation}  />
        </>
    )
}

export default QuotationList