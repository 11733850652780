/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from "react";
import { userPermissionContext } from '../context/UserPagePermission';

function GetPagePermission(value) {
	const { pagesLink } = useContext(userPermissionContext);
	const [hasPermission, setPermission] = useState('');

	useEffect(() => {
		const permission = pagesLink.filter(function (el) {
			return (el.pagesLink === value);

		});

		if (pagesLink.length > 0) {
			if (permission.length > 0) {
				setPermission(1)
			}
			else {
				setPermission(0)
			}
		}

	}, [pagesLink]);


	return hasPermission;

}
export default GetPagePermission;