/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react'
import Swal from 'sweetalert2'
import { getRequestOptions } from "../helpers/Fetchwrapper";
import validationErrorMsg from "../helpers/ValidationErrorMsg";
import Badge from 'react-bootstrap/Badge';
import axios from 'axios'
import { AppContext } from '../context/AppContext'


function CategoryCreate(props) {
    const [id, setId] = useState('');
    const [categoryName, setCategoryName] = useState('');
    const [status, setStatus] = useState('active');
    const [file, setFile] = useState('');
    const [image, setImage] = useState(null);
    const [prevImage, setPrevImage] = useState(null);
    const fileSelectRef = useRef();
    const myRef = useRef();
    const { token } = useContext(AppContext);

    useEffect(() => {
        if (id !== props.id) {
            setId(props.id);
            getCategoryDetails(props.id);
            window.scrollTo(0, 0);
            myRef.current.focus();
        }
    }, [props.id]);

    const handleChange = (e) => {
        if (e.target.files.length < 1) {
            return;
        }
        if (isValidFileUploaded(e.target.files[0])) {
            setImage(URL.createObjectURL(e.target.files[0]));
            setFile(e.target.files[0]);
            setPrevImage(null);
        } else {
            alert('File type not supported');
            setFile('');
            setImage(null);
        }
    }

    const isValidFileUploaded = (file) => {
        const validExtensions = ['png', 'jpeg', 'jpg']
        const fileExtension = file.type.split('/')[1]
        return validExtensions.includes(fileExtension)
    }

    const getCategoryDetails = (idCategory) => {
        fetch(process.env.REACT_APP_API_URL + "category_details/" + idCategory,
            getRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setCategoryName(resp.categoryName);
                setStatus(resp.status);
                setPrevImage(resp.fileName);
                setImage(null);
                setId(idCategory);
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }


    const statusType = [{ label: 'active', value: 'active' }, { label: 'In active', value: 'inactive' }]


    const handleSubmit = (evt) => {
        evt.preventDefault()
        if (id !== '') {
            var text = 'You want to update this Category!'
        } else {
            text = 'You want to add this Category!'
        }
        Swal.fire({
            title: 'Are you sure?',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: '#ec008c'
        }).then((result) => {
            if (result.value) {
                if (id !== '') {
                    var apiEnd = 'category_update'
                } else {
                    apiEnd = 'category_create'
                }

                const mewFormData = new FormData();
                mewFormData.append("myFile", file);
                mewFormData.append("id", id);
                mewFormData.append("categoryName", categoryName);
                mewFormData.append("status", status);
                const jwt = token;

                var authOptions = {
                    method: 'post',
                    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + jwt },
                    url: process.env.REACT_APP_API_URL + apiEnd,
                    data: mewFormData
                };

                axios(authOptions)
                    .then(response => {
                        const successMessage = response.data.successMessage;
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: successMessage
                        })
                        fileSelectRef.current.value = "";
                        setId('');
                        setCategoryName('');
                        setStatus('active');
                        setImage(null);
                        setPrevImage(null);
                        setFile('');
                        props.onCreate(Math.random() * 100)
                    })
                    .catch((error) => {
                        const errorsMessage = validationErrorMsg(error.response.data.errors).join("<br>");
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            html: errorsMessage,
                        })
                    });
            }
            else {
                fileSelectRef.current.value = "";
            }

        })
    }

    return (
        <div className='card-body'>
            <div className='ml-12' >
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='form-group row'>
                                <label
                                    htmlFor='name'
                                    className='col-lg-4 col-form-label'
                                    style={{ textAlign: 'right' }}
                                >
                                    Name<span className='required text-danger'></span>
                                </label>
                                <div className='col-lg-6'>
                                    <input
                                        type='text'
                                        name='name'
                                        id='name'
                                        ref={myRef}
                                        value={categoryName}
                                        onChange={(e) => setCategoryName(e.target.value)}
                                        className='form-control form-control-sm'
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="status" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }} >Status<span className="required text-danger"></span></label>
                                <div className="col-lg-6">
                                    <select className="form-select form-select-sm" id="status" name="status" value={status} onChange={(e) => setStatus(e.target.value)} >
                                        {statusType.map(function (item, id) {
                                            return <option key={id} value={item.value}>{item.label}</option>
                                        })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='form-group row'>
                                <label
                                    htmlFor='image'
                                    className='col-lg-4 col-form-label'
                                    style={{ textAlign: 'right' }}
                                >
                                    Image<span className='required text-danger'></span>
                                </label>
                                <div className='col-lg-6'>
                                    <input name="file" type="file" id="file" className="form-control form-control-sm" onChange={handleChange} ref={fileSelectRef} />
                                    <div className='mt-2'><Badge bg="danger">
                                        Note!
                                    </Badge> Image Size must be less than 5MB. Only PNG, JPG and JPEG files are allowed.</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            {image !== null &&
                                <div>
                                    <img src={image} alt="pic" style={{ width: "200px", height: "150px" }} />
                                </div>
                            }
                            {prevImage !== null &&
                                <div>
                                    <img src={process.env.REACT_APP_AWS_URL + "toolpati/category/" + id + "/" + prevImage} alt="pic" style={{ width: "200px", height: "150px" }} />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-5'></div>
                        <div className='col-6'>
                            <input
                                type='submit'
                                className='btn btn-sm btn-primary mt-2'
                                value={id !== '' ? 'Update' : 'Save'}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CategoryCreate
