import React, { useContext, useState, useRef } from 'react';
import { KTSVG } from '../../_metronic/helpers'
import { AppContext } from '../context/AppContext'
import { Tab, Tabs } from "react-bootstrap-v5";
import Badge from 'react-bootstrap/Badge';
import axios from 'axios'
import Swal from 'sweetalert2'
import validationErrorMsg from "../helpers/ValidationErrorMsg"

function MyProfile() {
	const { token, currentUser, userInfoReload } = useContext(AppContext);
	const [file, setFile] = useState();
	const [image, setImage] = useState(null);
	const fileSelectRef = useRef();

	const handleChange = (e) => {
		if (e.target.files.length < 1) {
			return;
		}
		if (isValidFileUploaded(e.target.files[0])) {
			setImage(URL.createObjectURL(e.target.files[0]));
			setFile(e.target.files[0]);
		} else {
			alert('File type not supported');
			setFile();
		}
	}

	const isValidFileUploaded = (file) => {
		const validExtensions = ['png', 'jpeg', 'jpg']
		const fileExtension = file.type.split('/')[1]
		return validExtensions.includes(fileExtension)
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText: 'No',
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.value) {
				const mewFormData = new FormData();
				mewFormData.append("myFile", file);

				const jwt = token;

				var authOptions = {
					method: 'post',
					headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + jwt },
					url: process.env.REACT_APP_API_URL + "profile_pic_upload",
					data: mewFormData
				};

				axios(authOptions)
					.then(response => {
						const successMessage = response.data.successMessage;
						Swal.fire({
							icon: 'success',
							title: 'Success',
							text: successMessage
						})
						fileSelectRef.current.value = "";
						userInfoReload();
					})
					.catch((error) => {
						const errorsMessage = validationErrorMsg(error.response.data.errors).join("<br>");
						Swal.fire({
							icon: 'error',
							title: 'Error',
							html: errorsMessage,
						})
					});

			}
			else {
				fileSelectRef.current.value = "";
			}
		})

	}

	return (
		<div className='card card-custom'>
			<div className='card-header'>
				<div className='card-title'>
					<KTSVG
						path='/media/svg/icons/General/User.svg'
						className='svg-icon-1 svg-icon-primary'
					/>
					<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
						My Profile
					</span>
				</div>
			</div>
			<div className='card-body'>
				<div className='ml-12'>
					<Tabs defaultActiveKey="basicInfo" id="uncontrolled-tab-example" className="mb-3">
						<Tab eventKey="basicInfo" title="Basic Information">
							<div className='col-6'>
								<table className='table table-bordered table-hover'>
									{currentUser !== null && currentUser !== undefined &&
										<tbody>
											<tr>
												<th>Name</th>
												<td>{currentUser.fullName}</td>
											</tr>
											<tr>
												<th>Email</th>
												<td>{currentUser.email}</td>
											</tr>
											<tr>
												<th>Phone Number</th>
												<td>{currentUser.phoneNumber}</td>
											</tr>

										</tbody>
									}
								</table>
							</div>
						</Tab>
						<Tab eventKey="profilePic" title="Profile Picture">
							<h2>Change Picture:</h2>
							{image !== null &&
								<div>
									<img src={image} alt="pic" style={{ width: "200px", height: "150px" }} />
								</div>
							}

							{currentUser !== null && currentUser !== undefined && image === null &&
								<div>
									{currentUser.fileName !== 'default.jpg' ?
										<img src={`${process.env.REACT_APP_AWS_URL}brac/profile_picture/${currentUser.fileName}`} alt="pic" style={{ width: "200px", height: "150px" }} />
										:
										<img src={`media/avatars/${currentUser.fileName}`} alt="pic" style={{ width: "200px", height: "150px" }} />

									}
								</div>
							}
							<div className='form-group row mt-2'>
								<div className='col-6'>
									<input name="file" type="file" id="file" className="form-control form-control-sm" onChange={handleChange} ref={fileSelectRef} />
								</div>
							</div>
							<div className='mt-2'><Badge bg="danger">
								Note!
							</Badge> Image Size must be less than 5MB. Only PNG, JPG and JPEG files are allowed.</div>

							<button className="btn btn-sm btn-primary me-2 mt-2" onClick={handleSubmit}>
								Save
							</button>
						</Tab>
					</Tabs>
				</div>
			</div>
		</div>
	)
}

export default MyProfile