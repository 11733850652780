/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import Login from "../pages/Login";


const Routes: FC = () => {

	// var redirect_url:any = process.env.REACT_APP_URL;
	// redirect_url = btoa(redirect_url);
	// redirect_url = encodeURIComponent(redirect_url);
	let token: any = localStorage.getItem('MyToken');
	const jwt = JSON.parse(token);


	return (
		<Switch>

			<Route path="/error" component={ErrorsPage} />
			<Route path="/login" component={Login} />

			{(!jwt) ? (
				/* Redirect to `/auth` when user is not authorized */
				<Redirect to={{ pathname: '/login' }} />
			)
				: (
					<MasterLayout>
						<PrivateRoutes />
					</MasterLayout>
				)}
		</Switch>
	);
}
export { Routes }