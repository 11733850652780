/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import { getRequestOptions, postRequestOptions } from "../helpers/Fetchwrapper";
import validationErrorMsg from "../helpers/ValidationErrorMsg";
import AsyncSelect from 'react-select/async'

function CategoryEquipmentCreate(props) {
    const [id, setId] = useState('');
    const [category, setCategory] = useState({});
    const [equipmentName, setEquipmentName] = useState('');

    useEffect(() => {
        if (id !== props.id) {
            setId(props.id);
            getCategoryEquipmentDetails(props.id);
        }
    }, [props.id]);


    const getCategoryEquipmentDetails = (id) => {
        fetch(process.env.REACT_APP_API_URL + "category_equipment_details/" + id,
            getRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setEquipmentName(resp.equipmentName);
                setCategory({
                    label: resp.categoryName,
                    value: resp.idCategory,
                    idCategory: resp.idCategory
                });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    const getAllCategories = (inputValue, callback) => {

        if (!inputValue) {
            var url = process.env.REACT_APP_API_URL + "get_all_categories";

        }
        else {

            url = process.env.REACT_APP_API_URL + "get_all_categories?q=" + inputValue;
        }

        setTimeout(() => {
            fetch(url,
                getRequestOptions())

                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    const tempArray = resp.data.map(function (element) {
                        element.label = element.categoryName;
                        element.value = element.idCategory;
                        return element;
                    });
                    callback(tempArray);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        });
    }

    const handleCategoryChange = (category) => {
        setCategory(category);
    }

    const handleSubmit = (evt) => {
        evt.preventDefault()
        if (id !== '') {
            var text = 'You want to update this Equipment Size!'
        } else {
            text = 'You want to add this Equipment Size!'
        }
        Swal.fire({
            title: 'Are you sure?',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.value) {
                const formData = {
                    id: id,
                    category: category,
                    equipmentName: equipmentName
                }

                const requestOptions = postRequestOptions(formData);

                if (id !== '') {
                    var apiEnd = 'category_equipment_update'
                } else {
                    apiEnd = 'category_equipment_create'
                }

                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            if (id !== '') {
                                var successMsg = [`Equipment Size${resp.data.equipment_name} updated`]
                            } else {
                                successMsg = [`Equipment Size${resp.data.equipment_name} added`]
                            }

                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })
                            setId('');
                            setCategory({});
                            setEquipmentName('');
                            props.onCreate(Math.random() * 100)
                        } else {

                            const errorsMessage = validationErrorMsg(resp.errors).join("<br>");
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, 'catch the hoop')
                    })
            }
        })
    }

    return (
        <div className='card-body'>
            <div className='ml-12' >
                <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                        <label htmlFor="category" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }} > Select Category <span className="required text-danger"></span></label>
                        <div className="col-lg-5">
                            <AsyncSelect
                                value={category}
                                loadOptions={getAllCategories}
                                placeholder="Select Category"
                                onChange={handleCategoryChange}
                                menuPosition='fixed'
                                className="category-equipment-select"
                                defaultOptions
                            />
                        </div>
                    </div>


                    <div className='form-group row'>
                        <label
                            htmlFor='equipmentName'
                            className='col-lg-3 col-form-label'
                            style={{ textAlign: 'right' }}
                        >
                            Equipment Name<span className="required text-danger"></span>
                        </label>
                        <div className='col-lg-5'>
                            <input
                                type='text'
                                name='equipmentName'
                                id='equipmentName'
                                value={equipmentName}
                                onChange={(e) => setEquipmentName(e.target.value)}
                                className='form-control form-control-sm'
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-5'></div>
                        <div className='col-6'>
                            <input
                                type='submit'
                                className='btn btn-sm btn-primary mt-2'
                                value={id !== '' ? 'Update' : 'Save'}
                            />

                        </div>
                    </div>
                    {/* <pre>
            {JSON.stringify(name, null, 2)}
            {JSON.stringify(status, null, 2)}
          </pre> */}
                </form>
            </div>
        </div>
    )
}

export default CategoryEquipmentCreate
