import React, { useState } from 'react'
import DataTable from '../components/DataTable'
import { KTSVG } from '../../_metronic/helpers'
import UserCreate from '../components/UserCreate'
import Error from '../components/Error500'
import GetPagePermission from "../components/GetPagePermission";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function Users() {

	const hasPermission = GetPagePermission("/accesscontrol-users");
	const [newData, setNewData] = useState('');
	const [id, setId] = useState('');
	const [email, setEmail] = useState('');

	const onCreate = ({ id, email }) => {
		setNewData({ id: id, email: email });
	}

	const columns = [
		{ value: 'id', title: 'Id', style: { textAlign: 'center' } },
		{ value: 'name', title: 'Name', style: { textAlign: 'left' } },
		{ value: 'email', title: 'Email', style: { textAlign: 'left' } },
		{ value: 'phoneNumber', title: 'Phone Number', style: { textAlign: 'left' } },
		{
			value: 'status', title: 'Status', style: { textAlign: 'center' }, hasHtmlComponent: true, htmlComponent: ((row) => {
				return (
					<>
						{row.status === 'active' && <span className="badge badge-light-primary">Active</span>}
						{row.status === 'inactive' && <span className="badge badge-light-danger">Inactive</span>}
					</>
				)
			})
		},
		{
			value: 'action', title: 'Action', style: { textAlign: 'center' }, hasActionsComponent: true, actionsComponent: ((row) => {
				return (
					<>
						<button className="btn btn-light-primary btn-sm" onClick={() => onAction(row)}>
							<KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1 svg-icon-3' />
							Edit
						</button>
					</>)

			})
		}
	]

	const filters = {
		id: {
			filterType: 'like',
			filterValue: '',
		},
		name: {
			filterType: 'like',
			filterValue: '',
		},
		email: {
			filterType: 'like',
			filterValue: '',
		},
		phoneNumber: {
			filterType: 'like',
			filterValue: '',
		},
		status: {
			filterType: 'like',
			filterValue: '',
		}

	}

	const url = process.env.REACT_APP_API_URL + 'user_dt'

	const onAction = (data) => {
		setId(data.id);
		setEmail(data.name);

	}

	return (
		<>
			{hasPermission === 1 &&
				<div className='card card-custom'>
					<div className='card-header'>
						<div className='card-title'>
							<KTSVG
								path='/media/svg/icons/Communication/Add-user.svg'
								className='svg-icon-1 svg-icon-primary'
							/>
							<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
								Add new User
							</span>
						</div>
					</div>

					<div className='card-body'>
						<UserCreate onCreate={onCreate} id={id} email={email} />
						<div className="card-header">
							<div className="card-title">
								<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
								<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
									User List
								</span>
							</div>
						</div>
						<div className='card-body'>
							<DataTable url={url} columns={columns} filters={filters} newData={newData} />
						</div>
					</div>
				</div>
			}
			{hasPermission === 0 &&
				<Error />
			}
			{hasPermission === '' &&
				<div className='card card-custom' style={{ padding: "15px" }} >
					<SkeletonTheme>
						<p>
							<Skeleton
								containerClassName="avatar-skeleton"
								count={40} ></Skeleton>
						</p>
					</SkeletonTheme>
				</div>
			}
		</>
	)
}

export default Users
