/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRequestOptions } from '../helpers/Fetchwrapper'
import { KTSVG } from '../../_metronic/helpers'


function VendorDetails() {
    const { id } = useParams();
    const [vendorDetails, setVendorDetails] = useState({});

    useEffect(() => {
        if (id) {
            getVendorDetails();
        }
    }, [id]);

    const getVendorDetails = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'vendor_details/' + id,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setVendorDetails(resp.data)
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }


    return (
        <>
        <div className='card card-custom'>
            <div className='card-header'>
                <div className='card-title'>
                    <KTSVG
                        path='/media/icons/duotune/text/txt001.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                        Vendor Details
                    </span>
                </div>
            </div>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-6'>
                        <div className="d-flex flex-left flex-column">
                            <h4>Vendor Info</h4>
                        </div>
                        <table className='table table-bordered table-hover'>
                            <tbody>
                                <tr>
                                    <th>Company Name</th>
                                    <td>{vendorDetails?.companyName}</td>
                                </tr>
                                <tr>
                                    <th>Company BIN Number</th>
                                    <td>{vendorDetails?.companyBINNumber}</td>
                                </tr>
                                <tr>
                                    <th>Vendor Name</th>
                                    <td>{vendorDetails?.vendorName}</td>
                                </tr>
                                <tr>
                                    <th>Vendor NID Number</th>
                                    <td>{vendorDetails?.vendorNIDNumber}</td>
                                </tr>
                                <tr>
                                    <th>Vendor Contact Number</th>
                                    <td>{vendorDetails?.vendorContactNumber}</td>
                                </tr>
                                <tr>
                                    <th>Alternative Number</th>
                                    <td>{vendorDetails?.alternativeNumber}</td>
                                </tr>    
                                <tr>
                                    <th>Email</th>
                                    <td>{vendorDetails?.email}</td>
                                </tr>
                                <tr>
                                    <th>Location</th>
                                    <td>{vendorDetails?.locationName}</td>
                                </tr>
                                <tr>
                                    <th>Address</th>
                                    <td>{vendorDetails?.address}</td>
                                </tr>
                                 
                            
                            </tbody>
                        </table>
                    </div>
                    <div className='col-6'>
                        
                        <table className='table table-bordered table-hover'>
                            <tbody>
                                <tr>
                                    <th>Manager Name</th>
                                    <td>{vendorDetails?.managerName}</td>
                                </tr>
                                <tr>
                                    <th>Manager Number</th>
                                    <td>{vendorDetails?.managerNumber}</td>
                                </tr>
                                <tr>
                                    <th>Manager NID</th>
                                    <td>{vendorDetails?.managerNID}</td>
                                </tr>
                               
                            </tbody>
                        </table>
                        </div>

                </div>

                 <div className='row'>
                    <div className='col-12'>
                        <div className="d-flex flex-left flex-column">
                            <h3>Bank Details</h3>
                        </div>
                        <table className='table table-bordered table-hover'>
                            <tbody>
                                <tr>
                                    <th>Bank AC Name</th>
                                    <td>{vendorDetails?.bankACName}</td>
                                </tr>
                                <tr>
                                    <th>Account Number</th>
                                    <td>{vendorDetails?.accountNumber}</td>
                                </tr>
                                <tr>
                                    <th>Bank Name</th>
                                    <td>{vendorDetails?.bankName}</td>
                                </tr>
                                <tr>
                                    <th>Branch Name</th>
                                    <td>{vendorDetails?.branchName}</td>
                                </tr>
                                <tr>
                                    <th>MFS Account</th>
                                    <td>{vendorDetails?.mfsAccount}</td>
                                </tr>
                                <tr>
                                    <th>MFS Service Provider</th>
                                    <td>{vendorDetails?.mfsServiceProvider}</td>
                                </tr>
                                <tr>
                                    <th>MFS Account Number</th>
                                    <td>{vendorDetails?.mfsAccountNumber}</td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                    </div>

            </div>
        </div>

       
    </>
    )
}

export default VendorDetails